import { useMemo, useEffect } from "react";
import { GrowthBook } from "@growthbook/growthbook-react";
import { config } from "@/config";
import { useUser } from "@/providers/useUser";

const useGrowthBook = () => {
  const { user } = useUser();

  const growthbook = useMemo(() => {
    const gb = new GrowthBook({
      apiHost: config.VITE_GROWTHBOOK_API_HOST,
      clientKey: config.VITE_GROWTHBOOK_CLIENT_KEY,
      enableDevMode: true,
      attributes: {
        id: user?.id,
      },
      trackingCallback: (experiment, result) => {
        // eslint-disable-next-line no-console
        console.log("Experiment Viewed", {
          experimentId: experiment.key,
          variationId: result.key,
        });
      },
    });
    gb.init({
      streaming: true,
    });
    return gb;
  }, [user?.id]);

  useEffect(() => {
    if (user) {
      growthbook.setAttributes({ id: user?.id });
      growthbook.refreshFeatures();
    }
  }, [user, growthbook]);

  return growthbook;
};

export default useGrowthBook;
