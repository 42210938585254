import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from "react";
import ZoomVideo, {
  ConnectionState,
  MediaSDKEncDecPayload,
  ReconnectReason,
  RecordingClient,
} from "@zoom/videosdk";
import ZoomContext from "./zoom-context";
import ZoomMediaContext from "./media-context";
import CommandContext from "./cmd-context";
import ChatContext from "./chat-context";
import { mediaReducer, mediaShape } from "./reducer/MediaReducer";
import { ChatClient, CommandChannelClient, MediaStream } from "../types/zoom";
import { config } from "@/config";
import { clientReducer, clientStore } from "./reducer/ClientReducer";
import { useUser } from "@/providers/useUser";
import { VideoStateProvider } from "./video-state-context";

export interface MeetingArgs {
  topic: string;
  token: string;
  username: string;
  password?: string;
}

interface AppProps {
  children: ReactNode;
}

const ZoomProvider = (props: AppProps) => {
  const { children } = props;
  const { user } = useUser();
  const [loading, setIsLoading] = useState(true);
  const [isFailover, setIsFailover] = useState<boolean>(false);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [zmChatClient, setChatClient] = useState<ChatClient | null>(null);
  const [zmcommandClient, setCommandClient] =
    useState<CommandChannelClient | null>(null);
  const [isVideoActive, setIsVideoActive] = useState(false);
  const [isOtherUserVideoActive, setIsOtherUserVideoActive] = useState(false);
  const [loadingText, setLoadingText] = useState("");
  const [sdkKey] = useState(config.SDK_KEY_ZOOM);
  const screenSharedElement = useRef(null);
  const screenSharedElementVideo = useRef(null);
  const videoCamerasElement = useRef(null);
  const [status, setStatus] = useState<string>("closed");
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const [recordingClient, setrecordingClient] = useState<
    typeof RecordingClient | undefined
  >(undefined);
  const [clientState, dispatchClient] = useReducer(clientReducer, clientStore);
  const mediaContext = useMemo(
    () => ({
      ...mediaState,
      mediaStream,
      isVideoActive,
      isOtherUserVideoActive,
      setIsVideoActive,
      setIsOtherUserVideoActive,
    }),
    [mediaState, mediaStream]
  );
  const zmClient = ZoomVideo.createClient();
  const initZoomClient = useCallback(
    async ({ token, topic, username }: MeetingArgs) => {
      setIsLoading(true);

      try {
        // Esperar a que zmClient.init se resuelva
        await zmClient.init("es-ES", "Global", {
          patchJsMedia: true,
          stayAwake: true,
          enforceMultipleVideos: true,
          leaveOnPageUnload: true,
        });

        // Llamar a zmClient.join y esperar a que se resuelva
        if (zmClient.getSessionInfo().isInMeeting) {
          await zmClient.leave();
        }

        await zmClient.join(topic, token, username);

        // Obtener el MediaStream después de que zmClient.join se haya resuelto
        const stream = zmClient.getMediaStream();
        setIsLoading(false);
        setMediaStream(stream);

        if (user) {
          await zmClient.changeName(
            `${user?.name} ${user?.lastName}`,
            Number(username)
          );
        }

        const chatClient = zmClient.getChatClient();
        const commandClient = zmClient.getCommandClient();
        setCommandClient(commandClient);
        setChatClient(chatClient);
        setrecordingClient(zmClient?.getRecordingClient());
      } catch (error: unknown) {
        setIsLoading(false);
        throw new Error(
          error && (error as Error).message
            ? (error as Error).message
            : "Error resetting password, please try again"
        );
      }
    },
    [zmClient, user]
  );

  const cleanup = async () => {
    ZoomVideo.destroyClient();
  };

  useEffect(() => {
    return () => {
      cleanup();
    };
  }, [sdkKey]);

  const onConnectionChange = useCallback(
    (payload: { state?: ConnectionState; reason: ReconnectReason }) => {
      const { state = null, reason } = payload;

      if (state === ConnectionState.Reconnecting) {
        setIsLoading(true);
        setIsFailover(true);
        setStatus("connecting");

        if (reason === ReconnectReason.Failover) {
          setLoadingText("Session Disconnected,Try to reconnect");
        } else if (
          reason === ReconnectReason.JoinSubsession ||
          reason === ReconnectReason.MoveToSubsession
        ) {
          setLoadingText(`Joining in subsession...`);
        } else if (reason === ReconnectReason.BackToMainSession) {
          setLoadingText("Returning to Main Session...");
        }
      } else if (state === ConnectionState.Connected) {
        setStatus("connected");
        if (isFailover) {
          setIsLoading(false);
        }
      } else if (state === ConnectionState.Closed) {
        setStatus("closed");
      }
    },
    [isFailover]
  );

  const onMediaSDKChange = useCallback((payload: MediaSDKEncDecPayload) => {
    const { action, type, result } = payload;
    dispatch({ type: `${type}-${action}`, payload: result === "success" });
  }, []);

  useEffect(() => {
    zmClient.on("connection-change", onConnectionChange);
    zmClient.on("media-sdk-change", onMediaSDKChange);
    return () => {
      zmClient.off("connection-change", onConnectionChange);
      zmClient.off("media-sdk-change", onMediaSDKChange);
    };
  }, [zmClient, onConnectionChange, onMediaSDKChange]);

  return (
    <ZoomContext.Provider
      value={{
        clientState,
        initZoomClient: (args: MeetingArgs) => initZoomClient(args),
        dispatch: dispatchClient,
        zmClient: zmClient,
        refVideosElements: {
          screenSharedElementAudience: screenSharedElement.current,
          screenSharedElementVideo: screenSharedElementVideo.current,
          videoCamerasElement: videoCamerasElement.current,
        },
        videoCamerasElement: "video-player-container",
        dataSessionAuth: clientState?.dataSessionAuth,
        clienteRecording: recordingClient,
      }}
    >
      <ZoomMediaContext.Provider
        value={{
          mediaContext,
          loadingZoom: loading,
          loadingText,
          statusZoom: status,
          dispatch,
        }}
      >
        <ChatContext.Provider value={zmChatClient}>
          <CommandContext.Provider value={zmcommandClient}>
            <VideoStateProvider>{children}</VideoStateProvider>
          </CommandContext.Provider>
        </ChatContext.Provider>
      </ZoomMediaContext.Provider>
    </ZoomContext.Provider>
  );
};

export default ZoomProvider;
