import { Avatar, AvatarProps } from "@chakra-ui/react";
import { useUser } from "../providers/useUser";

const AvatarSB = ({
  bgColor = "primary.500",
  boxSize = "40px",
  ...props
}: AvatarProps): JSX.Element => {
  const { user } = useUser();

  return (
    <Avatar
      bgColor={bgColor}
      boxSize={boxSize}
      color={"white"}
      name={`${user?.name ?? ""} ${user?.lastName ?? ""}`}
      size={"sm"}
      pt={"1px"}
      {...props}
    />
  );
};

export default AvatarSB;
