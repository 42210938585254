import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  radioAnatomy.keys
);

const radioTheme = defineMultiStyleConfig({
  baseStyle: {
    container: {
      mr: "24px",
    },
  },
  defaultProps: {
    colorScheme: "primary",
  },
});

export { radioTheme };
