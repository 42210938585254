import { Box, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { AppRoute } from "@/AppRoute";
import { Ico } from "@/assets/icons";
import { useUser } from "@/providers/useUser";
import { UserRole } from "@/schemaTypes";

import { MenuItem } from "./MenuItem";
import { TestIds } from "@/utils/testIds";
import useGA4Event, {
  GA4EventAction,
  GA4EventCategory,
} from "@/hooks/useGA4Event";

const AcademyTabs = (): JSX.Element => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { hasRoles } = useUser();
  const { sendEvent } = useGA4Event();

  const onClick =
    (location: string, GA4Action: GA4EventAction, GA4Label: string) => () => {
      if (GA4Action) {
        sendEvent({
          action: GA4Action,
          label: GA4Label,
          category: GA4EventCategory.Click,
        });
      }
      navigate(location);
    };

  const isPremiumUser = hasRoles([UserRole.PremiumSubscriber]);
  const isBasicUser = hasRoles([UserRole.BasicSubscriber]);
  const isPro = hasRoles([UserRole.Pro]);
  const isAdmin = hasRoles([UserRole.Admin]);
  const isAdvancedBootcampUser = hasRoles([UserRole.AdvancedBootcampUser]);
  const isBootcampUser = hasRoles([UserRole.BootcampUser]);
  const hasAdminMenu = hasRoles([
    UserRole.Admin,
    UserRole.Editor,
    UserRole.Mentor,
  ]);

  return (
    <Box as={"nav"} pb={"8px"} mb={"12px"}>
      {(isPro || isAdmin || isBasicUser || isPremiumUser) && (
        <>
          <Text opacity={0.6} p={"8px 16px"} variant={"pretitle"}>
            {t("Learn to launch")}
          </Text>
          <MenuItem
            data_cy={TestIds.Diplomats}
            icon={<Ico.GraduationCap />}
            label={"Diplomas"}
            onClick={onClick(
              AppRoute.Programs,
              GA4EventAction.AcademyDiplomaTabClick,
              "Academy diploma tab click"
            )}
            to={AppRoute.Programs}
          />
          <MenuItem
            data_cy={TestIds.BootcampsLaunch}
            activeMatch={new RegExp(AppRoute.BootcampsRelease)}
            icon={<Ico.StarShooting />}
            label={"Bootcamps: Lanzamiento"}
            onClick={onClick(
              AppRoute.BootcampsRelease,
              GA4EventAction.AcademyBootcampsReleaseTabClick,
              "Academy diploma tab click"
            )}
            to={AppRoute.BootcampsRelease}
          />
        </>
      )}
      {isBootcampUser &&
        !isPro &&
        !isAdmin &&
        !isBasicUser &&
        !isPremiumUser && (
          <>
            <Text opacity={0.6} p={"8px 16px"} variant={"pretitle"}>
              {t("Learn to launch")}
            </Text>
            <MenuItem
              data_cy={TestIds.BootcampsLaunch}
              activeMatch={new RegExp(AppRoute.BootcampsRelease)}
              icon={<Ico.StarShooting />}
              label={"Bootcamps: Lanzamiento"}
              onClick={onClick(
                AppRoute.BootcampsRelease,
                GA4EventAction.AcademyBootcampsReleaseTabClick,
                "Academy diploma tab click"
              )}
              to={AppRoute.BootcampsRelease}
            />
          </>
        )}

      {(isPro || isAdmin || isBasicUser || isPremiumUser) && (
        <>
          <Text mt={"16px"} opacity={0.6} p={"8px 16px"} variant={"pretitle"}>
            {t("Learn to grow")}
          </Text>
          <MenuItem
            data_cy={TestIds.Specializations}
            activeMatch={new RegExp(AppRoute.Specializations)}
            icon={<Ico.Award />}
            label={"Especializaciones"}
            onClick={onClick(
              AppRoute.Specializations,
              GA4EventAction.AcademySpecializationsTabClick,
              "Academy specializations tab click"
            )}
            to={AppRoute.Courses}
          />
          <MenuItem
            data_cy={TestIds.AdvancedCourse}
            activeMatch={new RegExp(AppRoute.AdvancedCourse)}
            icon={<Ico.BookOpen />}
            label={"Advanced courses"}
            onClick={onClick(
              AppRoute.AdvancedCourse,
              GA4EventAction.AcademyAdvancedCoursesTabClick,
              "Academy advanced courses tab click"
            )}
            to={AppRoute.AdvancedCourse}
          />
          <MenuItem
            data_cy={TestIds.BootcampsAdvanced}
            activeMatch={new RegExp(AppRoute.BootcampsAdvanced)}
            icon={<Ico.StarShooting />}
            label={"Bootcamps: Advanced"}
            onClick={onClick(
              AppRoute.BootcampsAdvanced,
              GA4EventAction.AcademyBootcampsAdvancedTabClick,
              "Academy bootcamps advanced tab click"
            )}
            to={AppRoute.BootcampsAdvanced}
          />
        </>
      )}
      {isAdvancedBootcampUser &&
        !isPro &&
        !isAdmin &&
        !isBasicUser &&
        !isPremiumUser && (
          <>
            <Text
              mt={isAdvancedBootcampUser && !isBootcampUser ? "none" : "16px"}
              opacity={0.6}
              p={"8px 16px"}
              variant={"pretitle"}
            >
              {t("Learn to grow")}
            </Text>
            <MenuItem
              data_cy={TestIds.BootcampsAdvanced}
              activeMatch={new RegExp(AppRoute.BootcampsAdvanced)}
              icon={<Ico.StarShooting />}
              label={"Bootcamps: Advanced"}
              onClick={onClick(
                AppRoute.BootcampsAdvanced,
                GA4EventAction.AcademyBootcampsAdvancedTabClick,
                "Academy bootcamps advanced tab click"
              )}
              to={AppRoute.BootcampsAdvanced}
            />
          </>
        )}
      {isAdmin && (
        <>
          <MenuItem
            data_cy={TestIds.Orgs}
            activeMatch={/organizations\/?$/}
            icon={<Ico.Building />}
            label={"Orgs"}
            onClick={onClick(
              AppRoute.Organizations,
              GA4EventAction.AcademyOrganizationsTabClick,
              "Academy organizations tab click"
            )}
            to={AppRoute.Organizations}
          />
          <MenuItem
            data_cy={TestIds.Liveclasses}
            activeMatch={/clase-en-vivo\/new?$/}
            icon={<Ico.MonitorRecorder />}
            label={"Live classes"}
            onClick={onClick(
              `${AppRoute.LiveSessionAgoraInternal}/new`,
              GA4EventAction.AcademyLiveSessionsTabClick,
              "Academy live sessions tab click"
            )}
            to={`${AppRoute.LiveSessionAgoraInternal}/new`}
          />
          <MenuItem
            data_cy={TestIds.Badges}
            activeMatch={/badges\/?$/}
            icon={<Ico.Badge />}
            label={"Badges"}
            onClick={onClick(
              AppRoute.Badges,
              GA4EventAction.AcademyBadgesTabClick,
              "Academy badges tab click"
            )}
            to={AppRoute.Badges}
          />
          <MenuItem
            data_cy={TestIds.Badges}
            activeMatch={/badges\/?$/}
            icon={<Ico.LinkedIn />}
            label={"Nuevo componente livesessions"}
            onClick={onClick(
              AppRoute.LiveSessionsNewComponent,
              GA4EventAction.CancelLiveSessionAttendance,
              ""
            )}
            to={AppRoute.LiveSessionsNewComponent}
          />
          <MenuItem
            data_cy={TestIds.Orgs}
            activeMatch={/organizations\/?$/}
            icon={<Ico.Building />}
            label={"Aprende New Ui"}
            onClick={onClick(
              AppRoute.Learn,
              GA4EventAction.AcademyOrganizationsTabClick,
              "Academy organizations tab click"
            )}
            to={AppRoute.Learn}
          />
          <MenuItem
            data_cy={TestIds.Orgs}
            activeMatch={/organizations\/?$/}
            icon={<Ico.Building />}
            label={"Entrenamiento con guia new Ui"}
            onClick={onClick(
              AppRoute.GuideTraining,
              GA4EventAction.AcademyOrganizationsTabClick,
              "Academy organizations tab click"
            )}
            to={AppRoute.Organizations}
          />
        </>
      )}
      {hasAdminMenu && (
        <>
          {isAdmin && (
            <MenuItem
              data_cy={TestIds.Reports}
              activeMatch={/reports\/?$/}
              icon={<Ico.SquareChartBar />}
              label={"Reports"}
              onClick={onClick(
                AppRoute.Reports,
                GA4EventAction.AcademyReportsTabClick,
                "Academy reports tab click"
              )}
              to={AppRoute.Reports}
            />
          )}
        </>
      )}
      {(isPro || isBasicUser || isPremiumUser) && (
        <Box>
          <Text mt={"16px"} opacity={0.6} p={"16px 24px"} variant={"pretitle"}>
            {t("Learn more")}
          </Text>
          <MenuItem
            data_cy={TestIds.MasterClass}
            activeMatch={new RegExp(AppRoute.Workshop)}
            icon={<Ico.Talleres />}
            label={"Workshops"}
            onClick={onClick(
              AppRoute.Workshop,
              GA4EventAction.AcademyMasterclassesTabClick,
              "Academy talleres de inicio tab click"
            )}
            to={AppRoute.Workshop}
          />
          <MenuItem
            data_cy={TestIds.MasterClass}
            activeMatch={new RegExp(AppRoute.MasterClass)}
            icon={<Ico.LaptopPlay />}
            label={"Masterclasses"}
            onClick={onClick(
              AppRoute.MasterClass,
              GA4EventAction.AcademyMasterclassesTabClick,
              "Academy masterclasses tab click"
            )}
            to={AppRoute.MasterClass}
          />
        </Box>
      )}
    </Box>
  );
};

export { AcademyTabs };
