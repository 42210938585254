import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Heading,
  Hide,
  Show,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { AppRoute } from "@/AppRoute";
import { useUser } from "@/providers/useUser";

import { AcademyTabs } from "./AcademyTabs";
import type { SideMenuProps } from "./types";
import { ConsultingButton } from "../Consulting";

const SideMenu = ({ show = false }: SideMenuProps): JSX.Element => {
  const { t } = useTranslation();
  const { user } = useUser();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Flex
      bgColor={bgColor}
      borderRight={show ? "1px" : "0px"}
      borderColor={borderColor}
      direction={"column"}
      flexShrink={0}
      h={"calc(100vh - var(--header-height))"}
      justify={isMobile ? "flex-start" : "space-between"}
      opacity={show ? 1 : 0}
      overflow={"hidden auto"}
      pos={isMobile ? "fixed" : "sticky"}
      py={"16px"}
      top={"var(--header-height)"}
      transition={"opacity 0.7s ease-in-out"}
      w={show ? "270px" : "0"}
      zIndex={1000}
      css={{
        "&::-webkit-scrollbar": {
          width: "0",
        },
        scrollbarWidth: "none",
        msOverflowStyle: "none",
        "&:hover": {
          "&::-webkit-scrollbar": {
            width: "3px",
          },
          scrollbarWidth: "auto",
        },
        "&::-webkit-scrollbar-thumb": {
          opacity: 0,
          transition: "opacity 0.3s",
        },
        "&:hover::-webkit-scrollbar-thumb": {
          opacity: 0.5,
        },
      }}
    >
      {user && (
        <>
          <Hide above={"md"}>
            <Accordion allowToggle px={"24px"}>
              <AccordionItem borderTop={"none"}>
                <AccordionButton _hover={{ bgColor: "none" }} p={"12px"}>
                  <Heading flex={1} textAlign={"start"}>
                    {t("Academy")}
                  </Heading>
                  <AccordionIcon color={"primary.300"} />
                </AccordionButton>
                <Box mx={"-24px"}>
                  <AccordionPanel as={AcademyTabs} />
                </Box>
              </AccordionItem>
              <AccordionItem>
                <AccordionButton as={Link} p={"12px"} to={AppRoute.Community}>
                  <Heading>{t("Community")}</Heading>
                </AccordionButton>
              </AccordionItem>
              <AccordionItem>
                <AccordionButton as={Link} p={"12px"} to={AppRoute.Solutions}>
                  <Heading>{t("Solutions")}</Heading>
                </AccordionButton>
              </AccordionItem>
              <AccordionItem>
                <AccordionButton as={Link} p={"12px"} to={AppRoute.Products}>
                  <Heading>{t("Products")}</Heading>
                </AccordionButton>
              </AccordionItem>
            </Accordion>
          </Hide>
          <Show above={"md"}>
            <AcademyTabs />
          </Show>
        </>
      )}
      <ConsultingButton isCard={true} />
    </Flex>
  );
};

export { SideMenu };
