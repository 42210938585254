import { Box, Flex, IconButton } from "@chakra-ui/react";
import AcademicFilter from "./AcademicFilter";
import { useRef, useState } from "react";
import { Ico } from "@/assets/icons";

interface AcademyCarouselProps {
  children: React.ReactNode[];
  filterOptions: string[];
  handleFilterChange: (filter: string) => void;
}

const AcademyCarousel: React.FC<AcademyCarouselProps> = ({
  children,
  filterOptions,
  handleFilterChange,
}) => {
  const [canScrollLeft, setCanScrollLeft] = useState<boolean>(false);
  const [canScrollRight, setCanScrollRight] = useState<boolean>(true);
  const [showScrollButtons, setShowScrollButtons] = useState<boolean>(false);
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const isDragging = useRef<boolean>(false);
  const startX = useRef<number>(0);
  const initialScrollLeft = useRef<number>(0);

  const handleScroll = () => {
    if (sliderRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = sliderRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft + clientWidth + 5 < scrollWidth);
    }
  };

  const scrollToLeft = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: -700, behavior: "smooth" });
    }
  };

  const scrollToRight = () => {
    if (sliderRef.current) {
      sliderRef.current.scrollBy({ left: 700, behavior: "smooth" });
    }
  };

  const onMouseMove = (e: React.MouseEvent) => {
    if (!isDragging.current || !sliderRef.current) return;
    e.preventDefault();
    const x = e.pageX - sliderRef.current.offsetLeft;
    const walk = (x - startX.current) * 2;
    sliderRef.current.scrollLeft = initialScrollLeft.current - walk;
  };

  const onMouseDown = (e: React.MouseEvent) => {
    if (sliderRef.current) {
      isDragging.current = true;
      startX.current = e.pageX - sliderRef.current.offsetLeft;
      initialScrollLeft.current = sliderRef.current.scrollLeft;
    }
  };

  const onMouseLeave = () => {
    isDragging.current = false;
  };

  const onMouseUp = () => {
    isDragging.current = false;
  };
  return (
    <Flex flexDir={"column"}>
      <AcademicFilter
        filterOptions={filterOptions}
        handleFilterChange={handleFilterChange}
      />
      <Box
        position="relative"
        onMouseEnter={() => setShowScrollButtons(true)}
        onMouseLeave={() => setShowScrollButtons(false)}
      >
        <Flex alignItems="center">
          {canScrollLeft && showScrollButtons && (
            <IconButton
              aria-label="Scroll left"
              icon={<Ico.SliderArrowIzq fontSize={"80px"} />}
              onClick={scrollToLeft}
              position="absolute"
              left="-60px"
              zIndex="1"
              bg={"none"}
              _hover={{ bg: "none" }}
              transform="translateY(-50%)"
            />
          )}
          <Box
            position="relative"
            ref={sliderRef}
            display="flex"
            flexDir="row"
            gap={4}
            overflowX="auto"
            css={{
              "&::-webkit-scrollbar": {
                width: "0px",
                height: "0px",
              },
            }}
            onScroll={handleScroll}
            onMouseDown={onMouseDown}
            onMouseLeave={onMouseLeave}
            onMouseUp={onMouseUp}
            onMouseMove={onMouseMove}
          >
            {children}
          </Box>
          {canScrollRight && showScrollButtons && (
            <IconButton
              aria-label="Scroll right"
              icon={<Ico.SliderArrowDer fontSize={"80px"} />}
              onClick={scrollToRight}
              position="absolute"
              bg={"none"}
              _hover={{ bg: "none" }}
              right="-60px"
              zIndex="1"
              transform="translateY(-50%)"
            />
          )}
        </Flex>
      </Box>
    </Flex>
  );
};

export default AcademyCarousel;
