import React, { useEffect, useState } from "react";
import { Box, Menu, Text, MenuItem, Divider } from "@chakra-ui/react";
import { t } from "i18next";
import { Trans } from "react-i18next";
import { Ico } from "@/assets/icons";
import { useLocation, useNavigate } from "react-router-dom";
import { AdminRoute } from "@/AppRoute";

const SideAcademyAdminMenu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const initialTab = (() => {
    const pathAfterAcademia = location.pathname.split("/academia/")[1];
    return pathAfterAcademia ? pathAfterAcademia.split("/")[0] : "";
  })();
  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const handleSelect = (option: string) => {
    setSelectedOption(option);
    navigate(
      option === "liveClasses"
        ? `${AdminRoute.AdministrationPanel}${AdminRoute.Academy}${AdminRoute.LiveSessions}`
        : `${AdminRoute.AdministrationPanel}${AdminRoute.Academy}${AdminRoute.Bootcamps}`
    );
  };

  useEffect(() => {
    setSelectedOption(initialTab);
  }, [initialTab]);

  return (
    <Box w="200px" pt={4}>
      <Menu>
        <Text
          opacity={0.6}
          p={"8px 16px"}
          variant={"pretitle"}
          textAlign={"center"}
        >
          {t("menu")}
        </Text>
        <Divider />
        <MenuItem
          color={
            selectedOption === "clases-en-vivo"
              ? "primary.500"
              : "secondary.100"
          }
          icon={<Ico.MonitorRecorder />}
          onClick={() => handleSelect("liveClasses")}
          bg={"transparent"}
        >
          <Trans>Live classes</Trans>
        </MenuItem>
        <MenuItem
          color={
            selectedOption === "bootcamps" ? "primary.500" : "secondary.100"
          }
          icon={<Ico.StarShooting />}
          onClick={() => handleSelect("bootcamps")}
          bg={"transparent"}
        >
          <Trans>Bootcamps</Trans>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default SideAcademyAdminMenu;
