import React from "react";
import { Box, Flex, Tag, Text } from "@chakra-ui/react";
import { LiveSession, LiveSessionType, Maybe } from "@/schemaTypes";
import { Ico } from "@/assets/icons";
import LivePreview from "@/components/LiveSessionsZoom/LivePreview/LivePreview";
import { parseLiveSessionType } from "./utils";
import { Trans } from "react-i18next";
import { Button } from "@/components/Button";

interface PreviewCardProps {
  sessionSelectedStarted?: LiveSession;
  enterToSession: (
    dateStart?: Date,
    session?: Maybe<LiveSession>,
    fromPreview?: boolean
  ) => void;
}

const PreviewCard: React.FC<PreviewCardProps> = (props) => {
  const { sessionSelectedStarted, enterToSession } = props;
  const liveSessionId = sessionSelectedStarted?.id;

  return (
    <Flex
      borderRadius={"8px"}
      width={"100%"}
      maxH={"261px"}
      flexDirection={"column"}
      justifyContent={"flex-end"}
      overflow={"hidden"}
      alignSelf={"stretch"}
      position={"relative"}
    >
      <Tag
        pos={"absolute"}
        top={"8px"}
        left={"8px"}
        bg={"error.300"}
        color={"#fff"}
      >
        LIVE
      </Tag>
      <Box
        w={"100%"}
        height={"200px"}
        backgroundRepeat={"no-repeat"}
        borderRadius={"8px"}
        backgroundSize={"100%"}
        backgroundPosition={"center"}
        backgroundImage={"/img/cv-started.webp"}
      >
        {liveSessionId && <LivePreview liveSessionId={liveSessionId} />}
      </Box>

      <Flex
        w={"100%"}
        flexGrow={1}
        alignItems={"flex-start"}
        justify={"space-between"}
        flexDir={"column"}
        gap={"16px"}
      >
        <Box>
          <Text
            fontWeight={700}
            mt={"12px"}
            variant={"caption"}
            textTransform={"uppercase"}
          >
            <Trans>
              {parseLiveSessionType(
                sessionSelectedStarted?.liveSessionType ||
                  LiveSessionType.Livesession
              )}
            </Trans>
          </Text>
          <Text fontWeight={600} variant={"caption"}>
            {sessionSelectedStarted?.name}
          </Text>
        </Box>

        <Button
          variant={"link"}
          onClick={() => enterToSession(sessionSelectedStarted?.startDate)}
          rightIcon={<Ico.ArrowRight width={"20px"} height={"20px"} />}
        >
          <Trans>Enter Live Class</Trans>
        </Button>
      </Flex>
    </Flex>
  );
};

export default PreviewCard;
