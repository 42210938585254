import { Box, Flex } from "@chakra-ui/react";
import { useRef } from "react";
import { Helmet } from "react-helmet";

import { useRect } from "@/hooks/useRect";

import { BackButton } from "./BackButton";
import { PageContainerProps } from "./types";
import { responsive, usePageTitle } from "./utils";

const PageContainer = ({
  children,
  goBackTo,
  header,
  maxW = "1800px",
  minH,
  title,
  px = responsive.gap,
  noGap = false,
  dark = false,
  onGoBack = () => {},
  ...restProps
}: PageContainerProps): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);
  const rect = useRect(ref);

  const gapY = { base: "16px", lg: "24px", "2xl": "32px" };

  return (
    <Flex
      align={"center"}
      direction={"column"}
      flex={1}
      justify={"center"}
      minH={minH}
      bgColor={dark ? "secondary.300" : "-moz-initial"}
      pb={noGap || dark ? "0px" : "70px"}
      px={px}
    >
      <Helmet>
        <title>{usePageTitle(title)}</title>
      </Helmet>
      {header ? (
        <Box alignSelf={"start"} mt={{ base: "16px", xl: "24px" }}>
          {header}
        </Box>
      ) : null}
      {goBackTo === undefined ? null : (
        <Box maxW={maxW} mt={noGap ? "0px" : gapY} w={"100%"}>
          <BackButton to={goBackTo} onGoBack={onGoBack} />
        </Box>
      )}
      <Box
        {...restProps}
        css={`
          --page-gap: ${responsive.gap};
          --page-w: ${rect.width}px;
        `}
        my={noGap ? "0px" : gapY}
        maxW={maxW}
        ref={ref}
        rounded={"8px"}
        w={"100%"}
      >
        {children}
      </Box>
    </Flex>
  );
};

export { PageContainer };
