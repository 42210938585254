import React, { useContext, useEffect, useState, useCallback } from "react";
import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import { useEventsZoom } from "../Hooks/useEventsZoom";
import { useGetVideosUser } from "../Hooks/useGetVideosUser";
import ZoomClientContext from "../context/zoom-context";
import { LiveSessionStatus } from "@/schemaTypes";
import { CameraGrid } from "./Controls/Camera/CameraGrid";
import { useVideoState } from "../context/videoState/useVideoState";
import { useQuery } from "@apollo/client";
import { GetJwtAuthLiveSessionDocument } from "@/components/LiveSessionsZoom/graphql/getJwtAuthLiveSession.generated";
import { EVENTS } from "../types/LiveBar.type";

interface PropsInterface {
  userID: string;
}

const STATUS_IMAGES = {
  CLASS_NOT_STARTED: "/img/cv-started.webp",
  CAMERA_OFF: "/img/camara-off.webp",
  CLASS_FINISHED: "/img/cv-finished.webp",
};

enum ClassStatus {
  NOT_STARTED,
  IN_PROGRESS,
  FINISHED,
}

export const LiveVideo: React.FC<PropsInterface> = ({ userID }) => {
  const { zmClient, dataSessionAuth } = useContext(ZoomClientContext);
  const { state, dispatch } = useVideoState();
  const [classStatus, setClassStatus] = useState<ClassStatus>(
    ClassStatus.NOT_STARTED
  );
  const [showCamera, setShowCamera] = useState(false);
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const [, setStatusSession] = useState<string>("");
  const [, setStatusCamera] = useState("");
  const [isFullScreen, setIsFullScreen] = useState(false);

  const { data: sessionData, refetch } = useQuery(
    GetJwtAuthLiveSessionDocument,
    {
      variables: {
        liveSessionsId: dataSessionAuth?.session?.id || "",
        liveStream: true,
        isPortal1: !!userID,
        dataPortal1Input: {
          userId: userID || "",
        },
      },
      skip: !dataSessionAuth?.session?.id,
      fetchPolicy: "network-only",
    }
  );

  useEventsZoom(setStatusSession, setStatusCamera);
  useGetVideosUser(setStatusCamera);

  const getBackgroundImage = useCallback(() => {
    if (state.isScreenShareActive) return "";
    switch (classStatus) {
      case ClassStatus.NOT_STARTED:
        return STATUS_IMAGES.CLASS_NOT_STARTED;
      case ClassStatus.IN_PROGRESS:
        return showCamera ? "" : STATUS_IMAGES.CAMERA_OFF;
      case ClassStatus.FINISHED:
        return STATUS_IMAGES.CLASS_FINISHED;
    }
  }, [state.isScreenShareActive, classStatus, showCamera]);

  const updateClassStatus = useCallback(() => {
    if (!zmClient || !sessionData?.getJwtAuthLiveSession) return;

    const participants = zmClient.getAllUser();
    const hasHostOrCoHost = participants.some(
      (user) => user.isHost || user.isManager
    );
    const hasActiveCamera = participants.some((user) => user.bVideoOn);
    const sessionStatus = sessionData.getJwtAuthLiveSession.session?.status;
    const isScreenShared = zmClient.getAllUser().some((user) => user.sharerOn);

    switch (sessionStatus) {
      case LiveSessionStatus.Live:
        setClassStatus(ClassStatus.IN_PROGRESS);
        setShowCamera(hasActiveCamera);
        break;
      case LiveSessionStatus.Ended:
        setClassStatus(ClassStatus.FINISHED);
        setShowCamera(false);
        break;
      case LiveSessionStatus.Scheduled:
        if (hasHostOrCoHost && (hasActiveCamera || isScreenShared)) {
          setClassStatus(ClassStatus.IN_PROGRESS);
          setShowCamera(hasActiveCamera);
        } else {
          setClassStatus(ClassStatus.NOT_STARTED);
          setShowCamera(false);
        }
        break;
      default:
        setClassStatus(ClassStatus.NOT_STARTED);
        setShowCamera(false);
    }

    dispatch({ type: "SET_SCREEN_SHARE_ACTIVE", payload: isScreenShared });
  }, [zmClient, sessionData, dispatch]);

  useEffect(() => {
    const handleUserChange = () => updateClassStatus();
    const handleVideoActiveChange = () => updateClassStatus();
    const handleActiveShareChange = (payload: { state: boolean }) => {
      dispatch({ type: "SET_SCREEN_SHARE_ACTIVE", payload: payload.state });
      updateClassStatus();
    };

    if (zmClient) {
      zmClient.on(EVENTS.USER_ADDED, handleUserChange);
      zmClient.on(EVENTS.USER_REMOVED, handleUserChange);
      zmClient.on(EVENTS.USER_UPDATED, handleUserChange);
      zmClient.on(EVENTS.HOST_CHANGED, handleUserChange);
      zmClient.on(EVENTS.VIDEO_ACTIVE_CHANGE, handleVideoActiveChange);
      zmClient.on(EVENTS.ACTIVE_SHARE_CHANGE, handleActiveShareChange);
    }

    return () => {
      if (zmClient) {
        zmClient.off(EVENTS.USER_ADDED, handleUserChange);
        zmClient.off(EVENTS.USER_REMOVED, handleUserChange);
        zmClient.off(EVENTS.USER_UPDATED, handleUserChange);
        zmClient.off(EVENTS.HOST_CHANGED, handleUserChange);
        zmClient.off(EVENTS.VIDEO_ACTIVE_CHANGE, handleVideoActiveChange);
        zmClient.off(EVENTS.ACTIVE_SHARE_CHANGE, handleActiveShareChange);
      }
    };
  }, [zmClient, updateClassStatus, dispatch]);

  useEffect(() => {
    updateClassStatus();
  }, [updateClassStatus, sessionData]);

  useEffect(() => {
    const interval = setInterval(() => {
      refetch();
    }, 15000);

    return () => clearInterval(interval);
  }, [refetch]);

  useEffect(() => {
    const handleFullscreenChange = () => {
      setIsFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);
    document.addEventListener("mozfullscreenchange", handleFullscreenChange);
    document.addEventListener("MSFullscreenChange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener(
        "webkitfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "mozfullscreenchange",
        handleFullscreenChange
      );
      document.removeEventListener(
        "MSFullscreenChange",
        handleFullscreenChange
      );
    };
  }, []);

  useEffect(() => {
    if (zmClient) {
      const isScreenShared = zmClient
        .getAllUser()
        .some((user) => user.sharerOn);
      dispatch({ type: "SET_SCREEN_SHARE_ACTIVE", payload: isScreenShared });
    }
  }, [zmClient, dispatch]);

  const fullscreenStyle = isFullScreen
    ? {
        width: "100vw",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "black",
      }
    : {};

  return (
    <Box height="100%" id="LiveVideoContainer" sx={fullscreenStyle}>
      <Box
        flexDirection={{ base: "column", lg: "row" }}
        id="StreamingVideo"
        border={!isMobile ? undefined : "1px solid #32323A"}
        height="100%"
        borderTopRadius="8px"
        position="relative"
        overflow="hidden"
      >
        <Box
          id="ContainerVideoImagenBackground"
          w="100%"
          h="100%"
          display="flex"
          flexDirection="column"
          backgroundImage={`url(${getBackgroundImage()})`}
          backgroundSize="cover"
          backgroundRepeat="no-repeat"
          backgroundPosition="center"
          justifyContent="flex-end"
          borderTopRadius="8px"
          position="absolute"
          top="0"
          left="0"
          opacity={state.isScreenShareActive ? 0 : 1}
          transition="opacity 0.3s ease-in-out"
        />

        <Flex
          id="content-container"
          width="100%"
          height="100%"
          overflow="hidden"
          flexDirection="row"
          justifyContent={
            state.isScreenShareActive && !showCamera ? "center" : "flex-start"
          }
          alignItems="center"
          position="relative"
        >
          <Box
            width={
              state.isScreenShareActive ? (showCamera ? "85%" : "100%") : "0%"
            }
            height="100%"
            display="flex"
            justifyContent="center"
            alignItems="center"
            transition="width 0.3s ease-in-out"
            overflow="hidden"
          >
            <video
              id="my-screen-share-content-video"
              style={{
                display: "none",
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
            <canvas
              id="my-screen-share-content-video-canvas"
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Box>

          {showCamera && (
            <Flex
              id="camera-container"
              width={state.isScreenShareActive ? "15%" : "100%"}
              justifyContent="center"
              alignItems="center"
              overflow="hidden"
              height="100%"
              transition="width 0.3s ease-in-out"
            >
              <Box w="100%" h="100%" display="flex" alignItems="center">
                <CameraGrid userId={zmClient?.getCurrentUserInfo()?.userId} />
              </Box>
            </Flex>
          )}
        </Flex>
      </Box>
    </Box>
  );
};
