/* eslint-disable no-console */
import { clientsClaim } from "workbox-core";
import { cleanupOutdatedCaches, precacheAndRoute } from "workbox-precaching";

import { config } from "./config";
import { useEffect } from "react";

declare const self: ServiceWorkerGlobalScope;

cleanupOutdatedCaches();
// eslint-disable-next-line no-underscore-dangle
precacheAndRoute(self.__WB_MANIFEST ?? []);
self.addEventListener("activate", (event) => {
  event.waitUntil(
    caches
      .keys()
      .then((cacheNames) => {
        return Promise.all(
          cacheNames.map((cacheName) => caches.delete(cacheName))
        );
      })
      .then(() => {
        self.clients.claim();
      })
      .then(() => {
        return self.clients
          .matchAll({ type: "window" })
          .then((windowClients) => {
            windowClients.forEach((windowClient) => {
              windowClient.navigate(windowClient.url);
            });
          });
      })
  );
});

self.addEventListener("install", () => {
  self.skipWaiting();
});

self.addEventListener("message", (event) => {
  if (event.data && event.data.type === "SKIP_WAITING") {
    self.skipWaiting();
  }
});

clientsClaim();

interface ServiceWorkerConfig {
  onUpdate?: (registration: ServiceWorkerRegistration) => void;
}

const register = (serviceWorkerConfig?: ServiceWorkerConfig) => {
  if (
    (config.VITE_APP_ENV === "production" ||
      config.VITE_APP_ENV === "staging" ||
      config.VITE_APP_ENV === "development") &&
    "serviceWorker" in navigator
  ) {
    const swUrl = `${config.PUBLIC_URL}/service-worker.js`;

    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
          console.log("Service Worker registrado con éxito:", registration);

          registration.onupdatefound = () => {
            const installingWorker = registration.installing;
            if (installingWorker === null) {
              return;
            }

            installingWorker.onstatechange = () => {
              if (installingWorker.state === "installed") {
                if (navigator.serviceWorker.controller) {
                  console.log(
                    "Nuevo contenido disponible; por favor, recargue."
                  );
                  if (serviceWorkerConfig && serviceWorkerConfig.onUpdate) {
                    serviceWorkerConfig.onUpdate(registration);
                  }
                } else {
                  console.log("Contenido caché para uso offline disponible.");
                }
              }
            };
          };
        })
        .catch((error) => {
          console.error("Error registering service worker:", error);
        });
    });
  }
};

const unregister = (): void => {
  if ("serviceWorker" in navigator) {
    navigator.serviceWorker.ready
      .then((registration) => {
        registration
          .unregister()
          .then(() => {
            console.log("Service Worker unregistered");
          })
          .catch((error) => {
            console.log("Error on service worker unregister:", error);
          });
      })
      .catch((error) => {
        console.error("Error unregistering service worker:", error);
      });
  }
};

const useServiceWorker = () => {
  useEffect(() => {
    register({
      onUpdate: (registration) => {
        const sw = registration.waiting;
        if (sw) {
          sw.addEventListener("statechange", (event) => {
            if (
              event.target &&
              "state" in event.target &&
              event.target.state === "activated"
            ) {
              window.location.reload();
            }
          });
          sw.postMessage({ type: "SKIP_WAITING" });
          sw.addEventListener("statechange", (e) => {
            type SWTarget = { state: "activated" } | null;
            if ((e.target as SWTarget)?.state === "activated") {
              window.location.reload();
            }
          });
          sw.postMessage({ type: "SKIP_WAITING" });
        }
      },
    });

    return () => {
      unregister();
    };
  }, []);
};

export { register, unregister, useServiceWorker };
