import { useLazyQuery, useMutation } from "@apollo/client";
import moment from "moment";
import {
  Box,
  Flex,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableContainer,
  Tabs,
  Tbody,
  Text,
  Input,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  Button,
  IconButton,
  Switch,
  Select,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { TiArrowSortedDown } from "react-icons/ti";
import { TiArrowSortedUp } from "react-icons/ti";
import { GetLiveSessionsDocument } from "@/components/LiveSessions/graphql/ListAllSessions.generated";
import { PageContainer } from "@/components/PageContainer";
import CreateMasterClass from "@/components/MasterClass/CreateMasterClass";
import { useUser } from "@/providers/useUser";
import {
  LiveSession,
  LiveSessionRecurrence,
  LiveSessionStatus,
  LiveSessionType,
  UserRole,
} from "@/schemaTypes";
import DateTimeInterface from "@/utils/dateTimeFormat/dateTimeInteface";
import DateTimeMoment from "@/utils/dateTimeFormat/dateTimeMoment";
import CreateLiveSessions from "./CreateLiveSessions";
import { Ico } from "@/assets/icons";
import { UpdateLiveSessionDocument } from "@/components/LiveSessions/graphql/updateLiveSession.generated";
import { DeleteLiveSessionDocument } from "@/components/LiveSessions/graphql/DeleteLiveSession.generated";
import { ConfirmDialog } from "@/components/ConfirmDialog";
import { t } from "i18next";
import { UpdateMasterClassDocument } from "@/components/MasterClass/graphql/updateMasterClass.generated";
import { DeleteMasterClassDocument } from "@/components/MasterClass/graphql/deleteMasterClass.generated";
import AdministrationPanel from "@/pages/Admin";
import Sidebar from "@/pages/Admin/Academy/SideAcademyAdminMenu";

moment.utc();

export const LiveSessionsAgoraPage = () => {
  const { hasRoles } = useUser();
  const [timezone, setTimezone] = useState<string>("America/Bogota");
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [sortedSessions, setSortedSessions] = useState<LiveSession[]>([]);
  const [startDown, setStartDown] = useState<boolean>(true);
  const [isCreateOpen, setIsCreateOpen] = useState<boolean>(false);
  const [createLiveSession, setCreateLiveSession] = useState<boolean>(false);
  const [actualPage, setActualPage] = useState<number>(1);
  const [maxSize, setMaxSize] = useState<number>(10);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [sessionType, setSessionType] = useState<LiveSessionType>(
    LiveSessionType.Livesession
  );
  const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] =
    useState<boolean>(false);
  const [selectedLiveSessionId, setSelectedLiveSessionId] =
    useState<string>("");
  const [
    getLiveSessions,
    { data: liveSessionsList, refetch: refetchLiveSession },
  ] = useLazyQuery(GetLiveSessionsDocument);
  const [
    getMasterClasses,
    { data: MasterClassesList, refetch: refetchMasterClasses },
  ] = useLazyQuery(GetLiveSessionsDocument);
  const [deleteLiveSession, { data: deleteLiveSessionData }] = useMutation(
    DeleteLiveSessionDocument
  );
  const [deleteMasterClass, { data: deleteMasterClassData }] = useMutation(
    DeleteMasterClassDocument
  );
  const [
    updateLiveSessions,
    { data: updatedata, loading: updateLiveSessionLoading },
  ] = useMutation(UpdateLiveSessionDocument);
  const [updateMasterClass, { loading: updateMasterClassLoading }] =
    useMutation(UpdateMasterClassDocument);
  const isAdmin = hasRoles([UserRole.Admin]);
  const dateTime: DateTimeInterface = new DateTimeMoment();
  const { isOpen: isOpen, onOpen: onOpen, onClose: onClose } = useDisclosure();
  const {
    isOpen: isMasterClassOpen,
    onOpen: onMasterclassOpen,
    onClose: onMasterclassClose,
  } = useDisclosure();
  const [selectedSessionsUpdate, setselectedSessionsUpdate] =
    useState<LiveSession>(() => ({
      id: "",
      userId: "",
      name: "",
      startDate: new Date(),
      endDate: new Date(),
      status: LiveSessionStatus.Scheduled,
      liveSessionType: LiveSessionType.Livesession,
      channelName: "",
      mentorName: "",
      mentorId: "",
      imageUrlMentor: "",
    }));

  const { geolocation } = useUser();

  const localTime = () => {
    setTimezone(geolocation.location);
  };

  const closeModal = () => {
    onClose();
    setIsCreateOpen(false);
  };
  const closeMasterclassModal = () => {
    onMasterclassClose();
    setIsCreateOpen(false);
  };

  const isCreateClose = () => {
    setIsCreateOpen(false);
  };

  const isNewCreateOpen = () => {
    setIsCreateOpen(true);
  };

  useEffect(() => {
    localTime();
  }, []);

  const hanlderSatrt = () => {
    setStartDown(!startDown);
  };

  const hanldeCancelClass = (liveSession: LiveSession) => {
    updateLiveSessions({
      variables: {
        updateLiveSessionOrMasterclassInput: {
          id: liveSession.id,
          userId: liveSession.userId,
          name: liveSession.name,
          startDate: liveSession.startDate,
          endDate: liveSession.endDate,
          programId: liveSession.programId || "",
          moduleId: liveSession.moduleId || "",
          isCancelled: !liveSession.isCancelled,
          liveSessionType:
            liveSession.liveSessionType || LiveSessionType.Livesession,
          imageUrlMentor: liveSession.imageUrlMentor || "",
          mentorId: liveSession.mentorId || "",
          brandId: liveSession.brandId || "",
          levelId: liveSession.levelId || "",
          subCategoryId: liveSession.subCategoryId || "",
          mentorName: liveSession.mentorName || "",
          recurrence: liveSession.recurrence as LiveSessionRecurrence,
        },
      },
    });
    setTimeout(() => {
      refetchLiveSession();
    }, 1000);
  };

  const hanldeHiddenMasterClass = (liveSession: LiveSession) => {
    updateMasterClass({
      variables: {
        updateMasterClassInput: {
          id: liveSession.id,
          endDate: liveSession.endDate,
          name: liveSession.name,
          startDate: liveSession.startDate,
          userId: liveSession.userId,
          liveSessionType: LiveSessionType.Masterclass,
          description: liveSession.description || "",
          imageUrlMentor: liveSession.imageUrlMentor || "",
          mentorId: liveSession.mentorId || "",
          isCancelled: !liveSession.isCancelled,
          brandId: liveSession.brandId || "",
          levelId: liveSession.levelId || "",
          subCategoryId: liveSession.subCategoryId || "",
        },
      },
    });
    setTimeout(() => {
      refetchMasterClasses();
    }, 1000);
  };

  const hanldeMasterClassOpen = () => {
    setCreateLiveSession(true);
  };

  const handleCloseCreateLiveSession = () => {
    setCreateLiveSession(false);
  };

  const handleDeleteLiveSession = () => {
    deleteLiveSession({
      variables: {
        liveSessionId: selectedLiveSessionId,
      },
    });
    setIsConfirmDeleteDialogOpen(false);
  };

  const handleDeleteMasterClass = () => {
    deleteMasterClass({
      variables: {
        masterClassId: selectedLiveSessionId,
      },
    });
    setIsConfirmDeleteDialogOpen(false);
  };

  const handleResetLiveSessionModal = () => {
    setIsConfirmDeleteDialogOpen(false);
    setSelectedLiveSessionId("");
  };

  const hanldeChangePage = (type: string) => () => {
    if (type === "next") {
      setActualPage(actualPage + 1);
    } else {
      setActualPage(actualPage === 1 ? 1 : actualPage - 1);
    }
  };

  const handlechangeLiveSessionType = (type: LiveSessionType) => {
    setSessionType(type);
  };

  useEffect(() => {
    getLiveSessions({
      variables: {
        page: actualPage,
        pageSize: maxSize,
        liveSessionType: [
          LiveSessionType.Livesession,
          LiveSessionType.OnboardingSession,
          LiveSessionType.QuestionsAndAnswers,
          LiveSessionType.SalesSession,
        ],
      },
    });
    getMasterClasses({
      variables: {
        page: actualPage,
        pageSize: maxSize,
        liveSessionType: [LiveSessionType.Masterclass],
      },
    });
  }, [actualPage, maxSize, sessionType]);

  useEffect(() => {
    refetchLiveSession();
    refetchMasterClasses();
  }, [
    updatedata,
    updateLiveSessionLoading,
    deleteLiveSessionData,
    updateMasterClassLoading,
    deleteMasterClassData,
  ]);

  useEffect(() => {
    let newSortedSessions;
    if (liveSessionsList && sessionType === LiveSessionType.Livesession) {
      const filteredSessions =
        liveSessionsList.getLiveSessions?.liveSessions?.filter((session) =>
          session?.name.toLowerCase().includes(searchTerm.toLowerCase())
        );
      if (startDown) {
        newSortedSessions = filteredSessions?.sort((a, b) =>
          moment(a?.startDate).isAfter(moment(b?.startDate)) ? 1 : -1
        );
      } else {
        newSortedSessions = filteredSessions?.sort((a, b) =>
          moment(a?.startDate).isAfter(moment(b?.startDate)) ? -1 : 1
        );
      }
      setTotalPages(liveSessionsList?.getLiveSessions?.totalPages || 1);
      setSortedSessions(newSortedSessions as LiveSession[]);
    } else {
      const filteredSessions =
        MasterClassesList?.getLiveSessions?.liveSessions?.filter((session) =>
          session?.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

      if (startDown) {
        newSortedSessions = filteredSessions?.sort((a, b) =>
          moment(a?.startDate).isAfter(moment(b?.startDate)) ? 1 : -1
        );
      } else {
        newSortedSessions = filteredSessions?.sort((a, b) =>
          moment(a?.startDate).isAfter(moment(b?.startDate)) ? -1 : 1
        );
      }
      setTotalPages(MasterClassesList?.getLiveSessions?.totalPages || 1);
      setSortedSessions(newSortedSessions as LiveSession[]);
    }
  }, [
    liveSessionsList,
    searchTerm,
    startDown,
    updateLiveSessionLoading,
    sessionType,
    MasterClassesList,
    updateMasterClassLoading,
  ]);

  return (
    <PageContainer>
      <AdministrationPanel />
      <Flex>
        <Sidebar />
        <Box w={"1450px"} ml={5} mt={3}>
          <Tabs>
            <TabList>
              <Tab
                _selected={{
                  color: "secondary.300",
                  borderBottom: "3px solid #088080",
                  fontWeight: "semibold",
                }}
                color={"neutral.400"}
                borderColor={"neutral.200"}
                fontSize={"16px"}
                w={"100%"}
                onFocus={() => {
                  handlechangeLiveSessionType(LiveSessionType.Livesession);
                }}
              >
                <Trans>Live classes</Trans>
              </Tab>
              <Tab
                _selected={{
                  color: "secondary.300",
                  borderBottom: "3px solid #088080",
                  fontWeight: "semibold",
                }}
                color={"neutral.400"}
                borderColor={"neutral.200"}
                fontSize={"16px"}
                w={"100%"}
                onFocus={() => {
                  handlechangeLiveSessionType(LiveSessionType.Masterclass);
                }}
              >
                <Trans>Masterclass</Trans>
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={10}>
                <Modal
                  onClose={handleCloseCreateLiveSession}
                  isOpen={createLiveSession}
                >
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>
                      <Trans>Agora live sessions edition</Trans>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <CreateLiveSessions
                        fetchLiveSessions={refetchLiveSession}
                        closeModal={handleCloseCreateLiveSession}
                      />
                    </ModalBody>
                  </ModalContent>
                </Modal>
                <Flex justifyContent={"space-between"}>
                  <Input
                    position={"initial"}
                    maxW={"300px"}
                    placeholder={t("SearchByName")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Flex alignItems={"center"} gap={3}>
                    <Text>
                      <Trans>maxSize</Trans>
                    </Text>
                    <Select
                      id="maxSize"
                      value={maxSize}
                      onChange={(e) => setMaxSize(Number(e.target.value))}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="1000">100+</option>
                    </Select>
                  </Flex>
                  <Button onClick={hanldeMasterClassOpen} bg={"primary.500"}>
                    <Text color={"neutral.50"}>
                      <Trans>AddNewLiveSession</Trans>
                    </Text>
                  </Button>
                </Flex>
                <Box>
                  <Box>
                    <Heading as={"h5"} size="md" mb="2" mt="4">
                      <Trans>List of live classes</Trans>
                    </Heading>
                  </Box>
                  <Box w={"100%"} overflow={"auto"}>
                    <TableContainer w={"100%"}>
                      <Table size="sm">
                        <Thead>
                          <Tr>
                            <Th>
                              <Trans>Name</Trans>
                            </Th>
                            <Th onClick={hanlderSatrt} cursor={"pointer"}>
                              <Flex gap={3}>
                                <Trans>Start Date</Trans>
                                {startDown ? (
                                  <TiArrowSortedDown />
                                ) : (
                                  <TiArrowSortedUp />
                                )}
                              </Flex>
                            </Th>
                            <Th>
                              <Trans>Status</Trans>
                            </Th>
                            <Th>
                              <Trans>ActiveCancel</Trans>
                            </Th>
                            <Th>
                              <Trans>Edit</Trans>
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {sortedSessions &&
                            sortedSessions.map(
                              (livesession: LiveSession, index: number) => {
                                const name = livesession?.name;
                                const maxLength = 50;
                                let firstPart = "";
                                let secondPart = "";

                                if (name && name.length > maxLength) {
                                  let sliceIndex = maxLength;
                                  while (
                                    sliceIndex > 0 &&
                                    name[sliceIndex] !== " "
                                  ) {
                                    sliceIndex--;
                                  }
                                  if (sliceIndex === 0) {
                                    firstPart = name.slice(0, maxLength);
                                    secondPart = name.slice(maxLength);
                                  } else {
                                    firstPart = name.slice(0, sliceIndex);
                                    secondPart = name.slice(sliceIndex + 1);
                                  }
                                } else {
                                  firstPart = name || "";
                                }

                                return (
                                  <Tr key={index} textAlign={"center"}>
                                    <Td w={"400px"}>
                                      <Box>
                                        {firstPart}
                                        {secondPart && (
                                          <>
                                            <br />
                                            {secondPart}
                                          </>
                                        )}
                                      </Box>
                                    </Td>
                                    <Td>
                                      {dateTime.fortmatOnlyDateAndDay(
                                        moment(livesession?.startDate),
                                        timezone
                                      )}
                                      <br />
                                      {dateTime.fortmatOnlyTime(
                                        moment(livesession?.startDate),
                                        timezone
                                      )}
                                    </Td>
                                    <Td>
                                      <Trans>{livesession.status}</Trans>
                                    </Td>
                                    <Td>
                                      <Switch
                                        ml={"40px"}
                                        size="lg"
                                        colorScheme="teal"
                                        isChecked={!livesession.isCancelled}
                                        onChange={() => {
                                          hanldeCancelClass(
                                            livesession as LiveSession
                                          );
                                        }}
                                      />
                                    </Td>
                                    <Td>
                                      <IconButton
                                        bg={"#088080"}
                                        colorScheme=""
                                        width="40px"
                                        height={"40px"}
                                        rounded="full"
                                        _hover={{ bgColor: "primary.200" }}
                                        aria-label="Add item"
                                        onClick={() => {
                                          setselectedSessionsUpdate(
                                            livesession as LiveSession
                                          );
                                          onOpen();
                                        }}
                                        icon={<Ico.Pencil />}
                                      />
                                      {isAdmin && (
                                        <IconButton
                                          bg={"#088080"}
                                          ml={3}
                                          _hover={{ bgColor: "primary.200" }}
                                          colorScheme=""
                                          width="40px"
                                          height={"40px"}
                                          rounded="full"
                                          aria-label="Remove item"
                                          onClick={() => {
                                            setSelectedLiveSessionId(
                                              livesession.id
                                            );
                                            setIsConfirmDeleteDialogOpen(true);
                                          }}
                                          icon={<Ico.Trash />}
                                        />
                                      )}
                                    </Td>
                                  </Tr>
                                );
                              }
                            )}
                        </Tbody>
                      </Table>
                    </TableContainer>
                    <Box>
                      <Flex justifyContent={"center"} mt={3}>
                        <Text
                          color={actualPage < 2 ? "grey" : "black"}
                          fontWeight={actualPage < 2 ? "normal" : "bold"}
                          cursor={actualPage < 2 ? "not-allowed" : "pointer"}
                          onClick={
                            actualPage < 2 ? () => {} : hanldeChangePage("prev")
                          }
                        >
                          {"<<"}
                        </Text>
                        <Text fontWeight={"bold"} mx={"20px"}>
                          {actualPage}
                        </Text>
                        <Text
                          fontWeight={
                            actualPage < totalPages ? "bold" : "normal"
                          }
                          color={actualPage < totalPages ? "black" : "grey"}
                          cursor={
                            actualPage < totalPages ? "pointer" : "not-allowed"
                          }
                          onClick={
                            actualPage < totalPages
                              ? hanldeChangePage("next")
                              : () => {}
                          }
                        >
                          {">>"}
                        </Text>
                      </Flex>
                      <Flex justifyContent={"center"}>
                        <Text>1 .. </Text>
                        <Text>
                          {". "}
                          {totalPages}
                        </Text>
                      </Flex>
                    </Box>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel p={10}>
                <Modal onClose={isCreateClose} isOpen={isCreateOpen}>
                  <ModalOverlay />
                  <ModalContent>
                    <ModalHeader>
                      <Trans>MasterClass edition</Trans>
                    </ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                      <CreateMasterClass
                        fetchMasterClass={refetchMasterClasses}
                        closeModal={closeMasterclassModal}
                      />
                    </ModalBody>
                  </ModalContent>
                </Modal>
                <Flex justifyContent={"space-between"}>
                  <Input
                    position={"initial"}
                    maxW={"300px"}
                    placeholder={t("SearchByName")}
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                  />
                  <Flex alignItems={"center"} gap={3}>
                    <Text>
                      <Trans>maxSize</Trans>
                    </Text>
                    <Select
                      id="maxSize"
                      value={maxSize}
                      onChange={(e) => setMaxSize(Number(e.target.value))}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="1000">100+</option>
                    </Select>
                  </Flex>
                  <Button onClick={isNewCreateOpen} bg={"primary.500"}>
                    <Text color={"neutral.50"}>
                      <Trans>AddNewMasterClass</Trans>
                    </Text>
                  </Button>
                </Flex>
                <Box>
                  <Box>
                    <Heading as={"h5"} size="md" mb="2" mt="4">
                      <Trans>List of MasterClasses</Trans>
                    </Heading>
                  </Box>
                  <Box w={"100%"} overflow={"auto"}>
                    <TableContainer w={"100%"}>
                      <Table size="sm">
                        <Thead>
                          <Tr>
                            <Th>
                              <Trans>Name</Trans>
                            </Th>
                            <Th onClick={hanlderSatrt} cursor={"pointer"}>
                              <Flex gap={3}>
                                <Trans>Start Date</Trans>
                                {startDown ? (
                                  <TiArrowSortedDown />
                                ) : (
                                  <TiArrowSortedUp />
                                )}
                              </Flex>
                            </Th>
                            <Th>
                              <Trans>Status</Trans>
                            </Th>
                            <Th>
                              <Trans>ActiveHidden</Trans>
                            </Th>
                            <Th>
                              <Trans>Edit</Trans>
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          {sortedSessions &&
                            sortedSessions.map(
                              (livesession: LiveSession, index: number) => {
                                const name = livesession?.name;
                                const maxLength = 50;
                                let firstPart = "";
                                let secondPart = "";

                                if (name && name.length > maxLength) {
                                  let sliceIndex = maxLength;
                                  while (
                                    sliceIndex > 0 &&
                                    name[sliceIndex] !== " "
                                  ) {
                                    sliceIndex--;
                                  }
                                  if (sliceIndex === 0) {
                                    firstPart = name.slice(0, maxLength);
                                    secondPart = name.slice(maxLength);
                                  } else {
                                    firstPart = name.slice(0, sliceIndex);
                                    secondPart = name.slice(sliceIndex + 1);
                                  }
                                } else {
                                  firstPart = name || "";
                                }

                                return (
                                  <Tr key={index} textAlign={"center"}>
                                    <Td w={"400px"}>
                                      <Box>
                                        {firstPart}
                                        {secondPart && (
                                          <>
                                            <br />
                                            {secondPart}
                                          </>
                                        )}
                                      </Box>
                                    </Td>
                                    <Td>
                                      {dateTime.fortmatOnlyDateAndDay(
                                        moment(livesession?.startDate),
                                        timezone
                                      )}
                                      <br />
                                      {dateTime.fortmatOnlyTime(
                                        moment(livesession?.startDate),
                                        timezone
                                      )}
                                    </Td>
                                    <Td>
                                      <Trans>{livesession.status}</Trans>
                                    </Td>
                                    <Td>
                                      <Switch
                                        ml={"40px"}
                                        size="lg"
                                        colorScheme="teal"
                                        isChecked={!livesession.isCancelled}
                                        onChange={() => {
                                          hanldeHiddenMasterClass(
                                            livesession as LiveSession
                                          );
                                        }}
                                      />
                                    </Td>
                                    <Td>
                                      <IconButton
                                        bg={"#088080"}
                                        colorScheme=""
                                        width="40px"
                                        height={"40px"}
                                        rounded="full"
                                        _hover={{ bgColor: "primary.200" }}
                                        aria-label="Add item"
                                        onClick={() => {
                                          setselectedSessionsUpdate(
                                            livesession as LiveSession
                                          );
                                          onMasterclassOpen();
                                        }}
                                        icon={<Ico.Pencil />}
                                      />
                                      {isAdmin && (
                                        <IconButton
                                          bg={"#088080"}
                                          ml={3}
                                          _hover={{ bgColor: "primary.200" }}
                                          colorScheme=""
                                          width="40px"
                                          height={"40px"}
                                          rounded="full"
                                          aria-label="Remove item"
                                          onClick={() => {
                                            setSelectedLiveSessionId(
                                              livesession.id
                                            );
                                            setIsConfirmDeleteDialogOpen(true);
                                          }}
                                          icon={<Ico.Trash />}
                                        />
                                      )}
                                    </Td>
                                  </Tr>
                                );
                              }
                            )}
                        </Tbody>
                      </Table>
                    </TableContainer>
                    <Box>
                      <Flex justifyContent={"center"} mt={3}>
                        <Text
                          color={actualPage < 2 ? "grey" : "black"}
                          fontWeight={actualPage < 2 ? "normal" : "bold"}
                          cursor={actualPage < 2 ? "not-allowed" : "pointer"}
                          onClick={
                            actualPage < 2 ? () => {} : hanldeChangePage("prev")
                          }
                        >
                          {"<<"}
                        </Text>
                        <Text fontWeight={"bold"} mx={"20px"}>
                          {actualPage}
                        </Text>
                        <Text
                          fontWeight={
                            actualPage < totalPages ? "bold" : "normal"
                          }
                          color={actualPage < totalPages ? "black" : "grey"}
                          cursor={
                            actualPage < totalPages ? "pointer" : "not-allowed"
                          }
                          onClick={
                            actualPage < totalPages
                              ? hanldeChangePage("next")
                              : () => {}
                          }
                        >
                          {">>"}
                        </Text>
                      </Flex>
                      <Flex justifyContent={"center"}>
                        <Text>1 .. </Text>
                        <Text>
                          {". "}
                          {totalPages}
                        </Text>
                      </Flex>
                    </Box>
                  </Box>
                </Box>
              </TabPanel>
            </TabPanels>
          </Tabs>
          <Modal onClose={onClose} isOpen={isOpen}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <Trans>Agora live sessions edition</Trans>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <CreateLiveSessions
                  liveSession={selectedSessionsUpdate}
                  editMode={true}
                  fetchLiveSessions={refetchLiveSession}
                  closeModal={closeModal}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
          <Modal onClose={onMasterclassClose} isOpen={isMasterClassOpen}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>
                <Trans>Agora live sessions edition</Trans>
              </ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <CreateMasterClass
                  masterClass={selectedSessionsUpdate}
                  editMode={true}
                  fetchMasterClass={refetchMasterClasses}
                  closeModal={closeMasterclassModal}
                />
              </ModalBody>
            </ModalContent>
          </Modal>
          {isConfirmDeleteDialogOpen && (
            <ConfirmDialog
              onCancel={handleResetLiveSessionModal}
              onConfirm={
                sessionType == LiveSessionType.Livesession
                  ? handleDeleteLiveSession
                  : handleDeleteMasterClass
              }
              title={t("Delete product")}
              message={t("Caution delete product")}
            />
          )}
        </Box>
      </Flex>
    </PageContainer>
  );
};
