import React from "react";
import {
  Text,
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalFooter,
  Heading,
  Flex,
  HStack,
} from "@chakra-ui/react";
import { LiveSession, MasterClass } from "@/schemaTypes";
import { ConvertDateToLocalString, GetHourstoDate } from "./utils";
import { useTranslation } from "react-i18next";
import { Ico } from "@/assets/icons";
import { LiveContentType } from "./types/LiveBar.type";
import { Button } from "../Button";
import { LiveSessionCard } from "@/pages/academy/LiveClasses/types";

interface ModalSchedulePositionProps {
  isSuccessSchedulling: boolean;
  timezone: string;
  isOpen: boolean;
  labelTypeSession?: string;
  onClose: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleSchedullingSession: (session: any) => Promise<void>;
  sessionToSchedule?: LiveContentType;
  sessionToSchedulesSameTime?:
    | LiveSession
    | MasterClass
    | LiveSessionCard
    | null;
}

const ModalSchedulePosition: React.FC<ModalSchedulePositionProps> = ({
  isSuccessSchedulling,
  sessionToSchedule,
  timezone,
  handleSchedullingSession,
  onClose,
  isOpen,
}) => {
  const { t } = useTranslation();

  const dateSessionLive = ConvertDateToLocalString(
    new Date(sessionToSchedule?.startDate ?? ""),
    timezone
  );

  const hourSessionLive = GetHourstoDate(
    new Date(sessionToSchedule?.startDate ?? ""),
    timezone
  );

  return (
    <Modal isCentered onClose={onClose} isOpen={isOpen} size={"lg"}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody>
          <Box
            flexDirection={"column"}
            display={"flex"}
            justifyContent={"center"}
            py={8}
            gap={3}
          >
            <Heading textAlign={"center"} as={"h5"}>
              ¡Prepárate para aprender y crecer!
            </Heading>
            {isSuccessSchedulling ? (
              <Text textAlign={"center"}>
                Por favor confirma tu participación a la Sesión de{" "}
                <Text as={"span"} variant={"bold"}>
                  {sessionToSchedule?.name}?
                </Text>
              </Text>
            ) : (
              <Text textAlign={"center"}>
                Tienes una sesión para ese día y hora.
                <br /> ¿Deseas cambiarla por esta?
              </Text>
            )}

            <Flex justifyContent={"center"} alignItems={"center"} gap={2}>
              <Box>
                <HStack>
                  <Ico.Calendar fontSize={"25px"} />
                  <Text
                    variant={"bold"}
                    css={{
                      "&:first-letter": {
                        textTransform: "uppercase",
                      },
                    }}
                  >
                    {timezone && dateSessionLive}
                  </Text>
                </HStack>
              </Box>
              <Box>
                <HStack>
                  <Ico.CalendarCheck fontSize={"25px"} />
                  <Text variant={"bold"} textTransform={"capitalize"}>
                    {hourSessionLive}
                  </Text>
                </HStack>
              </Box>
            </Flex>
          </Box>
        </ModalBody>
        <ModalFooter gap={5} justifyContent={"center"}>
          <Flex width={"100%"} gap={5} mb={5} mt={-5}>
            <Button
              width={"full"}
              height={"48px"}
              backgroundColor={"shades.white"}
              color={"primary.400"}
              border={"2px solid"}
              onClick={onClose}
            >
              {t("Back")}
            </Button>
            <Button
              width={"full"}
              height={"48px"}
              backgroundColor={"primary.300"}
              color={"shades.white"}
              onClick={() => handleSchedullingSession(sessionToSchedule)}
            >
              {isSuccessSchedulling ? t("Confirm") : t("Yes, change")}
            </Button>
          </Flex>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalSchedulePosition;
