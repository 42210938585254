export enum FilterTypes {
  LiveSessions = "liveSessions",
  OnlinePrograms = "onlinePrograms",
  GuideTrainings = "guideTrainings",
}

export enum FilterOptions {
  Diploma = "Diplomados",
  Specialization = "Especializaciones",
  AdvancedCourse = "Cursos Avanzados",
  All = "Todos",
}
