import { FilterOptions } from "./filterTypes";
import { Button } from "../Button";
import { useState } from "react";
import { Box, useBreakpointValue } from "@chakra-ui/react";

type AcademicFilterProps = {
  filterOptions: string[] | null;
  handleFilterChange: (filter: string) => void;
};

const AcademicFilter = ({
  filterOptions,
  handleFilterChange,
}: AcademicFilterProps) => {
  const [selected, setSelected] = useState<string>(FilterOptions.All);
  const isMobile = useBreakpointValue({ base: true, md: false });

  const handlePressFilter = (filter: string) => {
    setSelected(filter);
    handleFilterChange(filter);
  };

  return (
    <Box mb={"24px"} overflow="hidden">
      {filterOptions && filterOptions.length > 0 && (
        <Box
          display="flex"
          gap={3}
          overflowX={isMobile ? "scroll" : "visible"}
          css={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "-ms-overflow-style": "none", // IE and Edge
            "scrollbar-width": "none", // Firefox
          }}
        >
          {filterOptions.map((filter) => (
            <Button
              isSelected={selected === filter}
              key={filter}
              onClick={() => handlePressFilter(filter)}
              variant="option"
              flexShrink={0} // Prevent shrinking for mobile scrolling
            >
              {filter}
            </Button>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default AcademicFilter;
