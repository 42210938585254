import { Box } from "@chakra-ui/react";

import type { CircleIconProps } from "./types";

/**
 * @param props.bgColor Circle's color
 * @param props.boxSize Final element's size (Icon size + translation)
 * @param props.children Icon to show
 * @param props.fontSize Icon & circle's size
 * @returns Element with a circle below the icon with a given translation
 */
const CircleIcon = ({
  bgColor = "white",
  children,
  ...restProps
}: CircleIconProps): JSX.Element => {
  return (
    <Box boxSize={"1.25em"} pos={"relative"} flexShrink={0} {...restProps}>
      <Box
        bgColor={bgColor}
        bottom={"0"}
        boxSize={"1em"}
        left={"0"}
        pos={"absolute"}
        rounded={"50%"}
        transition={"background-color 0.3s ease-in-out"}
      />
      <Box pos={"absolute"} right={0} top={0}>
        {children}
      </Box>
    </Box>
  );
};

export { CircleIcon };
