import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { t } from "i18next";
import {
  Box,
  Flex,
  Heading,
  Spinner,
  Text,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { Trans } from "react-i18next";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import ZoomContext from "@/components/LiveSessionsZoom/context/zoom-context";
import ZoomMediaContext from "@/components/LiveSessionsZoom/context/media-context";
import { GetJwtAuthLiveSessionDocument } from "@/components/LiveSessionsZoom/graphql/getJwtAuthLiveSession.generated";
import { decodeBase64 } from "@/components/LiveSessions/utils/base64";
import { useUser } from "@/providers/useUser";
import { UserState } from "@/providers/UserProvider";
import { useConnectedClient } from "@/components/LiveSessionsZoom/Hooks/useConnectedClient";
import { RegisterAttendanceDocument } from "../graphql/registerAttendanceZm.generated";
import { StoreUserLeftEventDocument } from "../graphql/StoreUserLeftEventZm.generated";
import { BackButton } from "@/components/PageContainer/BackButton";
import { LiveVideo } from "@/components/LiveSessionsZoom/LiveVideo/LiveVideo";
import { CustomControls } from "@/components/LiveSessionsZoom/LiveVideo/Controls/CustomControls";
import { LeaveSession } from "@/components/LiveSessionsZoom/LiveVideo/Controls/Leave/Leave";
import Chat from "@/components/LiveSessionsZoom/Chat/Chat";
import { ModalActiveAudio } from "@/components/LiveSessionsZoom/LiveVideo/ModalActiveAudio";
import { UserRole } from "@/schemaTypes";

type ParamsRoute = {
  liveSessionId: string;
  name: string;
  userId: string;
};

const PageStreaming = () => {
  const MAX_RECONNECT_ATTEMPTS = 2;
  const RECONNECT_DELAY = 2000;
  const { liveSessionId, name: userName, userId } = useParams<ParamsRoute>();
  const { user: userData, hasRoles } = useUser();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const { dataAuthJWT } = useConnectedClient();
  const { zmClient, clientState, initZoomClient } = useContext(ZoomContext);
  const zoomMediaContext = useContext(ZoomMediaContext);
  const [getJwtAuthLiveSession] = useLazyQuery(GetJwtAuthLiveSessionDocument);
  const [registerAttendance] = useMutation(RegisterAttendanceDocument);
  const [storeUserLeftEvent] = useMutation(StoreUserLeftEventDocument);
  const [shouldReconnect, setShouldReconnect] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [videoKey, setVideoKey] = useState(0);
  const [reconnectAttempts, setReconnectAttempts] = useState(0);

  const isMentor = hasRoles([UserRole.Mentor]);

  const variablesGraphql = useMemo(
    () => ({
      liveSessionsId: liveSessionId || "",
      liveStream: true,
      isPortal1: !!userName,
      dataPortal1Input: {
        userId: userId || "",
      },
    }),
    [liveSessionId, userName, userId]
  );

  function getUsernameHelper(
    encodedUserName?: string,
    user?: UserState
  ): string {
    if (encodedUserName) {
      return decodeBase64(encodedUserName).replace(/[-_]/g, " ");
    }

    if (user) {
      if (user.name && user.lastName) {
        return `${user.name} ${user.lastName}`;
      }
      if (user.name) {
        return user.name;
      }
      if (user.id) {
        return user.id;
      }
    }

    return "Anonymous";
  }

  const handleReconnect = useCallback(async () => {
    if (!zmClient || !liveSessionId) return;

    try {
      setIsLoading(true);
      setReconnectAttempts((prev) => prev + 1);

      if (zoomMediaContext?.mediaContext?.mediaStream) {
        await zoomMediaContext.mediaContext.mediaStream.stopAudio();
      }

      const { data } = await getJwtAuthLiveSession({
        variables: variablesGraphql,
      });

      if (data && initZoomClient) {
        const {
          jwt,
          session: { channelName },
        } = data.getJwtAuthLiveSession;

        await initZoomClient({
          topic: channelName,
          token: jwt,
          username: getUsernameHelper(userName, userData),
        });

        setVideoKey((prevKey) => prevKey + 1);

        const isConnected = await new Promise((resolve) => {
          setTimeout(() => {
            resolve(zmClient.getSessionInfo().isInMeeting);
          }, 1000);
        });

        if (isConnected) {
          if (zoomMediaContext?.mediaContext?.mediaStream) {
            if (isMentor) {
              await zoomMediaContext.mediaContext.mediaStream.startAudio({
                autoStartAudioInSafari: false,
                syncButtonsOnHeadset: false,
                mute: true,
              });
            } else {
              await zoomMediaContext.mediaContext.mediaStream.startAudio({
                autoStartAudioInSafari: false,
                speakerOnly: true,
              });
            }
          }
          setShouldReconnect(false);
          setReconnectAttempts(0);
        } else {
          throw new Error("Failed to connect to the meeting");
        }
      }
    } catch (e: unknown) {
      SBErrorPubSub.publish({
        component: "PageStreaming.tsx handleReconnect",
        message: `Reconnection error: ${
          (e as Error).message || "Unknown error"
        }`,
        showInProd: false,
      });
      if ((e as { reason?: string })?.reason !== "closed") {
        SBErrorPubSub.publish({
          component: "PageStreaming.tsx handleReconnect",
          message: t(
            "An error occurred while trying to reconnect to the live class"
          ),
          showInProd: false,
        });
      }
      if (reconnectAttempts >= MAX_RECONNECT_ATTEMPTS) {
        window.location.reload();
      } else {
        setShouldReconnect(true);
      }
    } finally {
      setIsLoading(false);
    }
  }, [
    zmClient,
    liveSessionId,
    userName,
    userId,
    initZoomClient,
    zoomMediaContext,
    userData,
    reconnectAttempts,
  ]);

  const registryAttendance = useCallback(async () => {
    if (!dataAuthJWT) return;

    try {
      await registerAttendance({
        variables: {
          liveSessionsId: dataAuthJWT.getJwtAuthLiveSession.session.id,
          isPortal1: !!userName,
          dataPortal1Input: {
            userId: userId || "",
          },
        },
      });
    } catch (error) {
      SBErrorPubSub.publish({
        component: "PageStreaming.tsx line 165",
        message:
          (error as Error)?.message || t(`Failed to register attendance`),
        showInProd: false,
      });
    }
  }, [dataAuthJWT, userName, userId, registerAttendance]);

  // useEffect(() => {
  //   const handleVisibilityChange = () => {
  //     if (!document.hidden && !clientState?.isAuth) {
  //       handleReconnect();
  //     }
  //   };

  //   document.addEventListener("visibilitychange", handleVisibilityChange);

  //   return () => {
  //     document.removeEventListener("visibilitychange", handleVisibilityChange);
  //   };
  // }, [clientState?.isAuth]);

  // useEffect(() => {
  //   if (!clientState?.isAuth && !isLoading) {
  //     handleReconnect();
  //   }
  // }, [clientState?.isAuth, isLoading]);

  useEffect(() => {
    if (shouldReconnect) {
      const reconnectTimer = setTimeout(() => {
        handleReconnect();
      }, RECONNECT_DELAY);

      return () => clearTimeout(reconnectTimer);
    }
  }, [shouldReconnect, handleReconnect]);

  useEffect(() => {
    registryAttendance();

    return () => {
      if (dataAuthJWT) {
        storeUserLeftEvent({
          variables: {
            storeUserLeftEventInput: {
              liveSessionId: liveSessionId || "",
            },
          },
        });
      }
    };
  }, [dataAuthJWT]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (clientState?.isAuth && zmClient?.getSessionInfo().isInMeeting) {
      onOpen();
      setShouldReconnect(false);
    } else {
      timer = setTimeout(() => {
        if (!clientState?.isAuth || !zmClient?.getSessionInfo().isInMeeting) {
          setShouldReconnect(true);
        }
      }, 5000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [zmClient, clientState?.isAuth, onOpen]);

  return (
    <Box
      h={{ base: "100%", lg: "100vh" }}
      p={{
        base: "10px",
        sm: 10,
        md: 10,
        lg: 10,
        xl: 10,
        "2xl": 20,
      }}
      bgColor={"secondary.300"}
    >
      {zoomMediaContext?.loadingZoom || isLoading || shouldReconnect ? (
        <Box
          w={"100%"}
          height={{ base: "100vh", lg: "100%" }}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          flexDirection={"column"}
          gap={2}
        >
          <Spinner color="white" />
          <Text color={"shades.white"}>
            {shouldReconnect
              ? t("Reconnecting") + "..."
              : t("Connecting") + "..."}
          </Text>
        </Box>
      ) : (
        <>
          <Flex
            height="100%"
            alignItems="stretch"
            w={"100%"}
            flexDirection={{
              base: "column",
              lg: "row",
            }}
            gap={{ base: "90px", lg: 5 }}
          >
            <Box
              w={{ base: "100%", lg: "75%" }}
              h={{ base: "60vh", lg: "100%" }}
              borderRadius={"8px"}
            >
              <Flex justify={"space-between"} h={{ base: "10%", lg: "5%" }}>
                <BackButton dark={true} to={-1} />
                <Flex gap={"9px"} align={"baseline"} hidden={isMobile}>
                  <Text color={"neutral.200"} variant={"feedback"}>
                    {t("Connection problems?")}
                  </Text>
                  <Flex
                    gap={"8px"}
                    align={"center"}
                    cursor={"pointer"}
                    onClick={handleReconnect}
                  >
                    <Text color={"error.400"} variant={"caption"}>
                      {t("Reconnect")}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>

              <Flex
                flexDirection={"column"}
                h={{ base: "100%", lg: "95%" }}
                borderRadius={"8px"}
              >
                <Box flex={1} overflow={"hidden"} borderTopRadius={"8px"}>
                  <LiveVideo key={videoKey} userID={userId || ""} />
                </Box>

                <Box
                  h={{
                    base: "15%",
                    sm: "12%",
                    md: "12%",
                    lg: "12%",
                    xl: "12%",
                    "2xl": "8%",
                  }}
                >
                  <CustomControls />
                </Box>

                <Box
                  h={{
                    base: "30%",
                    sm: "15%",
                    md: "15%",
                    lg: "15%",
                    xl: "15%",
                    "2xl": "10%",
                  }}
                >
                  <Box
                    backgroundColor="#27272E"
                    color={"shades.white"}
                    p={"16px"}
                    gap={2}
                    flex={1}
                    h={"100%"}
                    display={"flex"}
                    flexDirection={"row"}
                    borderRight={"1px solid #32323A"}
                    borderBottom={"1px solid #32323A"}
                    borderLeft={"1px solid #32323A"}
                    borderBottomRightRadius={"8px"}
                    borderBottomLeftRadius={"8px"}
                    alignItems={"center"}
                    justifyContent={isMobile ? "flex-start" : "flex-start"}
                  >
                    <Box w={"100%"}>
                      <Text
                        size="12px"
                        color="shades.white"
                        fontWeight="semibold"
                        bgGradient="linear(to-r, #E5E5E9, #6A6A7A)"
                        bgClip="text"
                      >
                        <Trans>Live Class</Trans>
                      </Text>
                      <Heading as="h6" fontSize="18px">
                        {dataAuthJWT?.getJwtAuthLiveSession?.session.name}
                      </Heading>
                    </Box>
                    {isMobile && isMentor && (
                      <Box>
                        <LeaveSession />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Flex>
              <Flex
                gap={"9px"}
                align={"baseline"}
                pt={5}
                justifyContent={"center"}
                hidden={!isMobile}
                id="mobile-reconnect"
              >
                <Text color={"neutral.200"} variant={"feedback"}>
                  {t("Connection problems?")}
                </Text>
                <Flex
                  gap={"8px"}
                  align={"center"}
                  cursor={"pointer"}
                  onClick={handleReconnect}
                >
                  <Text color={"error.400"} variant={"caption"}>
                    {t("Reconnect")}
                  </Text>
                </Flex>
              </Flex>
            </Box>
            <Box
              width={{ base: "100%", md: "100%", lg: "25%" }}
              height={{ base: "100vh", lg: "100%" }}
              borderRadius={"8px"}
              mt={{ base: 5, lg: 0 }}
            >
              <Chat key={videoKey} />
            </Box>
          </Flex>
          <ModalActiveAudio
            isOpen={isOpen}
            onClose={onClose}
            onOpen={onOpen}
            stream={zoomMediaContext?.mediaContext.mediaStream}
          />
        </>
      )}
    </Box>
  );
};

export default PageStreaming;
