import mainLogo from "/img/logo_smartbeemo.svg";
import beemo from "/img/beemo.svg";
import {
  Accordion,
  Box,
  Flex,
  Img,
  useBreakpointValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { Ico } from "@/assets/icons";
import IconButton from "@/components/Button/Icon";
import { AppRoute } from "@/AppRoute";
import AcademyTabs from "./academyTabs";
import NavigationTooltipButton from "./navigationTooltipButton";
import NavigationPopoverButton from "./navigationPopoverButton";
import ManageTabs from "./manageTabs";
import ConnectTabs from "./connectTabs";
import { useNavigate } from "react-router-dom";

const NewSideMenu = ({ show = false }) => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const navigate = useNavigate();
  const [isMenuExpanded, setIsMenuExpanded] = useState(false);

  const menuExpandIcon = isMenuExpanded ? (
    <Ico.ChevronLeft fill="#1E1E23" />
  ) : (
    <Ico.ChevronRight fill="#1E1E23" />
  );

  const handleSetMenuExpandedDelayed = () => {
    setTimeout(() => {
      setIsMenuExpanded((val) => !val);
    }, 300);
  };

  return show ? (
    <Flex
      h={"100vh"}
      w={isMenuExpanded ? "248px" : "64px"}
      px={isMobile ? "8px" : "16px"}
      py={"24px"}
      bg={"#fff"}
      mr={"36px"}
      borderRight={"1px solid"}
      borderColor={"neutral.100"}
      flexDir={"column"}
      alignItems={isMenuExpanded ? "flex-start" : "center"}
      transition={"all 0.5s ease"}
      position={isMobile ? "fixed" : "sticky"}
      top={isMobile ? "60px" : 0}
      zIndex={1000}
      mt={isMobile ? "0" : "-60px"}
    >
      <Box display={isMobile ? "none" : "auto"}>
        <Img
          src={beemo}
          alt="beemo"
          minH={"32px"}
          maxH={"32px"}
          mb={"48px"}
          display={isMenuExpanded ? "none" : "block"}
        />
        <Img
          src={mainLogo}
          alt="logo"
          minH={"32px"}
          maxH={"32px"}
          mb={"48px"}
          display={isMenuExpanded ? "block" : "none"}
        />
      </Box>

      <IconButton
        display={isMobile ? "none" : "flex"}
        icon={menuExpandIcon}
        onClick={handleSetMenuExpandedDelayed}
        ariaLabel="Expandir"
        variant="primary"
        bg={"#fff"}
        borderRadius={"50%"}
        border={"3px solid"}
        borderColor={"neutral.100"}
        position={"absolute"}
        top={"56px"}
        right={isMenuExpanded ? "-20px" : "-20px"}
        _hover={{ bg: "auto" }}
        _active={{ bg: "neutral.100" }}
      />

      <Accordion
        as={Flex}
        w={"100%"}
        gap={"4px"}
        flexDir={"column"}
        allowToggle
      >
        <NavigationPopoverButton
          title="Aprende"
          baseRoutes={[AppRoute.Learn]}
          isMenuExpanded={isMenuExpanded}
          icon={<Ico.MonitorRecorder />}
          CustomPopoverContent={<AcademyTabs showPaddingLeft={false} />}
          AccordionContent={<AcademyTabs />}
          onClick={() => navigate(AppRoute.Learn)}
        />

        <NavigationPopoverButton
          title="Gestiona"
          baseRoutes={[AppRoute.Products, AppRoute.Consulting]}
          isMenuExpanded={isMenuExpanded}
          icon={<Ico.BoxMagnifyingGlass />}
          CustomPopoverContent={<ManageTabs showPaddingLeft={false} />}
          AccordionContent={<ManageTabs />}
        />

        <NavigationPopoverButton
          baseRoutes={[AppRoute.Community]}
          title="Conéctate"
          isMenuExpanded={isMenuExpanded}
          icon={<Ico.People />}
          CustomPopoverContent={<ConnectTabs showPaddingLeft={false} />}
          AccordionContent={<ConnectTabs />}
        />

        <NavigationTooltipButton
          label={"Aprovecha"}
          route={AppRoute.Solutions}
          isMenuExpanded={isMenuExpanded}
          icon={<Ico.StarShooting />}
        />

        <NavigationTooltipButton
          label={"Foro"}
          route={AppRoute.HelpCenter}
          isMenuExpanded={isMenuExpanded}
          icon={<Ico.CommunicationText2 />}
        />
      </Accordion>
    </Flex>
  ) : null;
};

export default NewSideMenu;
