import { Flex, Text } from "@chakra-ui/layout";
import { Button, useBreakpointValue } from "@chakra-ui/react";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useUser } from "@/providers/useUser";
import { AppRoute } from "@/AppRoute";
import { useFeatureValue } from "@growthbook/growthbook-react";
import { Ico } from "@/assets/icons";
import { CircleIcon } from "@/components/Icon/Circle";
import { ConsultingButtonProps } from "./types";
import { TestIds } from "@/utils/testIds";
import useGA4Event, {
  GA4EventAction,
  GA4EventCategory,
} from "@/hooks/useGA4Event";

const ConsultingButton = ({ isCard }: ConsultingButtonProps): JSX.Element => {
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { sendEvent } = useGA4Event();
  const { user } = useUser();

  const buttonColor = useFeatureValue("buttonColorChange", "#77D6FF");

  const gradient = {
    _active: { bg: buttonColor },
    _hover: { bgPos: "100%" },
    bg: `linear-gradient(90deg, #BEEBFF 0%, ${buttonColor} 100%)`,
    bgSize: "200%",
    transition: "background 0.3s",
  };

  const handleNavigate = useCallback(() => {
    sendEvent({
      action: GA4EventAction.ConsultingButtonClick,
      category: GA4EventCategory.Click,
      label: "Consulting button click",
    });
    navigate(AppRoute.Consulting);
    window.location.reload();
  }, [navigate, user?.id]);

  return (
    <Flex flexDir={"column"}>
      <Button
        {...gradient}
        border={"none"}
        colorScheme={"tertiary"}
        display={isCard === isMobile ? undefined : "none"}
        leftIcon={
          <CircleIcon boxSize={"24px"} fontSize={"20px"}>
            <Ico.MessageQuestion />
          </CircleIcon>
        }
        m={isCard ? "24px" : undefined}
        mb={0}
        onClick={handleNavigate}
        rounded={"8px"}
        size={"sm"}
        variant={"outline"}
        data-cy={TestIds.ConsultantButton}
      >
        {isCard === isMobile ? t("Consultings") : ""}
      </Button>
      {isCard ? (
        <Flex
          align={"center"}
          border={"2px"}
          borderColor={"neutral.200"}
          direction={"column"}
          justify={"center"}
          w={"230px"}
          m={"24px"}
          p={"16px"}
          rounded={"16px"}
        >
          <CircleIcon fontSize={"32px"} bgColor={"tertiary.300"}>
            <Ico.PlanetCursor title={t("Need help")} />
          </CircleIcon>
          <Text align={"center"} mt={"5px"} variant={"caption"}>
            {t("Product to sell")}
          </Text>
          <Button
            borderColor={"transparent !important"}
            color={"secondary.300 !important"}
            mt={"8px"}
            onClick={() => navigate(AppRoute.Productselection)}
            textDecor={"underline"}
            variant={"secondary"}
            w={"100%"}
            data-cy={TestIds.DiscoverHereButton}
          >
            {t("Discover Here")}
          </Button>
        </Flex>
      ) : null}
    </Flex>
  );
};

export { ConsultingButton };
