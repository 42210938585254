import { Avatar, Badge, Box, Flex, Text } from "@chakra-ui/react";
import { LiveSessionCard as LiveSessionCardType } from "./types";
import { Trans } from "react-i18next";
import { parseLiveSessionsDate, useLiveSessionStatus } from "./utils";
import { Button } from "@/components/Button";
import { LiveSessionType } from "@/schemaTypes";
import { Ico } from "@/assets/icons";

interface LiveSessionCardProps {
  session: LiveSessionCardType;
  handleScheduleClick: (session: LiveSessionCardType) => void;
  handleCancelScheduleClick: (session: LiveSessionCardType) => void;
  enterToSession: (session: LiveSessionCardType) => void;
}

const LiveSessionCard: React.FC<LiveSessionCardProps> = ({
  session,
  handleScheduleClick,
  handleCancelScheduleClick,
  enterToSession,
}) => {
  const { showCancelOption, showEnterOption, showRegisterOption } =
    useLiveSessionStatus(session);

  const parseLiveSessionType = (type: LiveSessionType) =>
    type.charAt(0).toUpperCase() +
    type.slice(1).toLowerCase().replace(/_/g, " ");

  return (
    <Flex
      key={session?.name}
      alignSelf={"stretch"}
      flexGrow={1}
      minW={"300px"}
      maxW={"300px"}
      gap={"8px"}
      flexDir={"column"}
      userSelect={"none"}
    >
      <Flex
        flexGrow={1}
        flexDir={"column"}
        rounded={"6px"}
        bg={"shades.200"}
        p={"12px"}
        gap={"8px"}
      >
        <Avatar w={"40px"} h={"40px"} src={session?.mentorAvatar} />
        <Box>
          <Text
            fontWeight={700}
            mt={"12px"}
            variant={"caption"}
            textTransform={"uppercase"}
          >
            <Trans>{parseLiveSessionType(session.liveSessionType)}</Trans>
          </Text>
          <Text fontWeight={600} variant={"caption"}>
            {session?.name}
          </Text>
        </Box>
        <Box>
          <Text fontWeight={600} variant={"caption"}>
            {parseLiveSessionsDate(session?.startDate ?? new Date()).format}
            {" | "}
            {parseLiveSessionsDate(session?.startDate ?? new Date()).time}
          </Text>
          <Text fontWeight={400} variant={"caption"}>
            Con: {session?.mentorName}
          </Text>
        </Box>
      </Flex>

      <Flex align={"flex-start"} gap={"8px"}>
        <Badge px={"8px"} bg={"neutral.50"} textTransform={"capitalize"}>
          <Text variant={"feedback"}>{session?.brand?.name}</Text>
        </Badge>
        <Badge px={"8px"} bg={"neutral.50"} textTransform={"capitalize"}>
          <Text variant={"feedback"}>{session?.subCategory?.name}</Text>
        </Badge>
        <Badge px={"8px"} bg={"neutral.50"} textTransform={"capitalize"}>
          <Text variant={"feedback"}>{session?.level?.name}</Text>
        </Badge>
      </Flex>

      <Flex>
        {session?.isCancelled && (
          <Button variant="link" isDisabled cursor={"default"}>
            <Trans>Canceled by the teacher</Trans>
          </Button>
        )}
        {showEnterOption && (
          <Button
            variant={"link"}
            onClick={() => enterToSession(session)}
            rightIcon={<Ico.ArrowRight width={"20px"} height={"20px"} />}
          >
            <Trans>Enter Live Class</Trans>
          </Button>
        )}
        {showRegisterOption && (
          <Button variant={"link"} onClick={() => handleScheduleClick(session)}>
            {session.liveSessionType === LiveSessionType.Masterclass
              ? "Regístrate"
              : "Guarda tu puesto"}
          </Button>
        )}
        {showCancelOption && (
          <Flex w={"100%"} justify={"space-between"}>
            <Button variant="link" isDisabled cursor={"default"}>
              Agendada
            </Button>

            <Button
              onClick={() => handleCancelScheduleClick(session)}
              variant="link"
            >
              Cancelar
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default LiveSessionCard;
