import { useLocation } from "react-router-dom";

export const useIsActive = (routes: string[]) => {
  const location = useLocation();
  const routesPrefixes = routes.map((route) => route.split("/")[1]);
  return routesPrefixes.some((route) => location.pathname.includes(route));
};

export const useIsSubPageActive = (routes: string[]) => {
  const location = useLocation();
  return routes.some((route) => location.pathname === route);
};

export const activeStyles = {
  icon: { fill: "#0F43DE" },
  button: { color: "primary.400", bg: "#E9F1FF", borderRadius: "8px" },
  subPageButton: { color: "primary.400" },
};
