import { Box, Flex, useBreakpointValue } from "@chakra-ui/react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";

import { AdminRoute, AppRoute, PublicRoute } from "@/AppRoute";
import type { NavigationProps } from "./types";
import { Header } from "./Header";
import NewSideMenu from "./newSideMenu";
import { SideMenu } from "./SideMenu";
import { useFeatureValue } from "@growthbook/growthbook-react";
import { NewHeader } from "./Header/newHeader";

const pathsWithoutSideBar: AppRoute[] = [AppRoute.Base];

const useIsPath = (paths: (AppRoute | AdminRoute)[]): boolean => {
  const { pathname } = useLocation();
  const cleanPathname = pathname === "/" ? pathname : pathname.substring(1);
  return useMemo(() => {
    return paths.some((path) => {
      const cleanPath = path === "/" ? path : path.substring(1);
      return cleanPathname.startsWith(cleanPath);
    });
  }, [cleanPathname, paths]);
};

const Navigation = ({ children }: NavigationProps): JSX.Element => {
  const showNewNavigation = useFeatureValue("newNavigation", false);

  const pathsWithoutSideBarDesktop: (AppRoute | AdminRoute)[] =
    showNewNavigation
      ? [
          AppRoute.SettingDevices,
          AppRoute.LiveSessionStreaming,
          AppRoute.LiveSessionBroadcast,
          AppRoute.ConnectWithRocketfy,
          PublicRoute.LiveSessionPortalV1 as unknown as AppRoute,
          PublicRoute.CreatePassword as unknown as AppRoute,
        ]
      : [
          AppRoute.Base,
          AppRoute.Profiling,
          AppRoute.SettingDevices,
          AppRoute.LiveSessionStreaming,
          AppRoute.LiveSessionBroadcast,
          AppRoute.Certificates,
          AppRoute.Certificate,
          AppRoute.ChinaAgent,
          AppRoute.Community,
          AppRoute.Consulting,
          AppRoute.MyOrdersProgress,
          AppRoute.MyProgress,
          AppRoute.Products,
          AppRoute.Productselection,
          AppRoute.Solutions,
          AppRoute.Users,
          AppRoute.MyProfileGamification,
          AppRoute.TermsAndConditions,
          AppRoute.ExecutionReport,
          PublicRoute.CreatePassword as unknown as AppRoute,
          AppRoute.ConnectWithRocketfy,
          ...pathsWithoutSideBar,
        ];

  const pathsWithoutTopBar = [
    AppRoute.Profiling,
    AppRoute.SuscriptionExpider,
    AppRoute.ConnectWithRocketfy,
    AppRoute.SettingDevices,
    AppRoute.LiveSessionStreaming,
    AppRoute.LiveSessionBroadcast,
  ];

  const { pathname } = useLocation();
  const [openSideBar, setOpenSideBar] = useState(false);
  const showTopBar = !useIsPath(pathsWithoutTopBar);
  const isMobile = useBreakpointValue({ base: true, lg: false });
  const showSideBar = !useIsPath(
    isMobile ? pathsWithoutSideBar : pathsWithoutSideBarDesktop
  );

  const sideBarState = showSideBar ? (openSideBar ? "open" : "close") : "hide";

  const toggleOpenSideBar = useCallback(() => {
    setOpenSideBar((val) => !val);
  }, []);

  useEffect(() => {
    setOpenSideBar(false);
  }, [pathname]);

  return (
    <Box position={"relative"}>
      {showTopBar &&
        (!showNewNavigation ? (
          <Header
            onClickMenuButton={toggleOpenSideBar}
            sideBar={sideBarState}
          />
        ) : (
          <NewHeader
            onClickMenuButton={toggleOpenSideBar}
            sideBar={sideBarState}
          />
        ))}
      <Flex>
        {!showNewNavigation ? (
          <SideMenu show={showSideBar && (!isMobile || openSideBar)} />
        ) : (
          <NewSideMenu show={showSideBar && openSideBar} />
        )}
        <Box flex={"1"} overflowX={"auto"}>
          {children}
        </Box>
      </Flex>
    </Box>
  );
};

export { Navigation };
