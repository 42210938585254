import { Box, Button, Flex, useColorModeValue } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface MenuItemProps {
  activeMatch?: RegExp;
  icon?: JSX.Element;
  label?: string;
  onClick: () => void;
  to: string;
  data_cy?: string;
}

const MenuItem = ({
  activeMatch,
  icon,
  label,
  onClick,
  to,
  data_cy,
}: MenuItemProps): JSX.Element => {
  const { pathname } = useLocation();
  const isActive = activeMatch
    ? activeMatch.test(pathname)
    : pathname.startsWith(to);
  const inactiveColor = useColorModeValue("secondary.300", "shades.white");
  const { t } = useTranslation();

  return (
    <Flex align={"center"} justify={"center"}>
      <Button
        border={"none"}
        borderRadius={"2px"}
        color={isActive ? "primary.300" : inactiveColor}
        fontWeight={isActive ? 600 : 400}
        gap={"12px"}
        justifyContent={"start"}
        onClick={onClick}
        py={"8px"}
        size={"sm"}
        transition={"all 0.3s"}
        variant={"ghost"}
        w={"100%"}
        data-cy={data_cy}
      >
        <Box opacity={isActive ? undefined : 0.6}>{icon}</Box>
        {label === undefined ? null : t(label)}
      </Button>
      <Box
        bgColor={isActive ? "primary.300" : "transparent"}
        h={"24px"}
        roundedLeft={"4px"}
        w={"4px"}
      />
    </Flex>
  );
};

export { MenuItem };
