export enum AppRoute {
  Base = "/",
  Login = "/login",
  Dashboard = "/dashboard",
  Courses = "/curso",
  AdvancedCourse = "/cursos-avanzados",
  Programs = "/diplomados",
  Free = "/free",
  Experiment = "/experimento",
  ExecutionReport = "/reporte-ejecucion",
  Forms = "/forms",
  Questions = "/preguntas",
  Modules = "/modulos",
  Mentors = "/mentores",
  Users = "/mi-perfil",
  Teams = "/teams",
  Sessions = "/sesion",
  MisPedidos = "/mis-pedidos",
  MyOrdersProgress = "/progreso-mis-pedidos",
  Tools = "/tools",
  MyProgress = "/mi-progreso",
  MyCertificates = "/mis-certificados",
  InteractiveContent = "/contenido-interactivo",
  CalendarEvents = "/calendario-eventos",
  Calendar = "/calendario",
  Badges = "/insignias",
  Cohorts = "/cohortes",
  Metrics = "/metricas",
  Unauthorized = "/sin-autorización",
  Reports = "/reportes",
  Gamification = "/gamificacion",
  Invites = "/invitacion",
  Certificates = "/certificados",
  Certificate = "/certificado",
  ConnectWithRocketfy = "/despegar-con-rocketfy",
  Organizations = "/organizaciones",
  Avatar = "/avatar",
  Notifications = "/notificaciones",
  Consulting = "/consultorias",
  Specializations = "/especializaciones",
  Profiling = "/perfilamiento",
  LiveSessionAgoraInternal = "/clase-en-vivo",
  LiveSessionStreaming = "/clase-en-vivo/streaming",
  LiveSessionBroadcast = "/clase-en-vivo/broadcast",
  LiveSessionsNewComponent = "/nuevo-componente-live-sessions",
  Community = "/comunidad",
  MasterClass = "/masterclass",
  Workshop = "/talleres-de-inicio",
  CommunityEvents = "/comunidad/eventos",
  Onboarding = "/onboarding",
  Solutions = "/soluciones",
  ChinaAgent = "/agente-china",
  ChinaAgentForm = "/agente-china/registro",
  ChinaAgentFormSuccess = "/agente-china/registro/exitoso",
  Products = "/productos",
  Productselection = "/productos/seleccion",
  BootcampsRelease = "/bootcamps-lanzamiento",
  BootcampsAdvanced = "/bootcamps-avanzados",
  SuscriptionExpider = "/suscripcion-vencida",
  Agenda = "/agenda",
  PreviousSessions = "/sesiones-previas",
  FrequentQuestions = "/preguntas-frecuentes",
  MyProfileGamification = "/mi-perfil/gamificacion",
  TermsAndConditions = "/terminos-y-condiciones",
  SettingDevices = "/configuracion-dispositivos",
  HelpCenter = "/centro-de-ayuda",
  OnlineProgramns = "/programas-online",
  GuideTraining = "/entrenamiento-con-guia",
  Amazon = "/amazon",
  Meli = "/mercado-libre",
  Dropshipping = "/dropshipping",
  Learn = "/aprende",
}

export enum PublicRoute {
  Enroll = "/enroll",
  ValidateEmail = "/validar-correo-electronico",
  ResetPassword = "/restablecer-contrasena",
  CreatePassword = "/crear-contrasena",
  ForgotPasssword = "/olvidaste-contrasena",
  ConductCode = "/conduct-code",
  ServiceTerms = "/service-terms",
  OSSAttributions = "/oss-attributions",
  SchoolNotFound = "/school-not-found",
  CLILogin = "/cli/login",
  SocialShare = "/social/share",
  LiveSession = "/live-session",
  LiveSessionPortalV1 = "/clase-en-vivo/portal-v1",
}

export enum AdminRoute {
  AdministrationPanel = "/panel-de-administracion",
  General = "/general",
  Academy = "/academia",
  Community = "/comunidad",
  Solutions = "/soluciones",
  Products = "/productos",
  Consultants = "/consultores",
  Bootcamps = "/bootcamps",
  LiveSessions = "/clases-en-vivo",
}

export const InitialRoute = AppRoute.Base;

export const isPublicRoute = (pathname: string): boolean =>
  pathname.includes(PublicRoute.Enroll) ||
  pathname.includes(PublicRoute.ValidateEmail) ||
  pathname.includes(PublicRoute.ForgotPasssword) ||
  pathname.includes(PublicRoute.ResetPassword) ||
  pathname.includes(PublicRoute.CLILogin) ||
  pathname.includes(PublicRoute.SchoolNotFound) ||
  pathname.includes(PublicRoute.SocialShare) ||
  pathname.includes(PublicRoute.OSSAttributions) ||
  pathname.includes(PublicRoute.LiveSession) ||
  pathname.includes(PublicRoute.LiveSessionPortalV1) ||
  pathname.includes(AppRoute.Base);
