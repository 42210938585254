/* eslint-disable react-hooks/rules-of-hooks */
import { AppRoute } from "@/AppRoute";
import { Button } from "@/components/Button";
import { Flex, Text } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { activeStyles, useIsSubPageActive } from "./utils";

const ManageTabs = ({ showPaddingLeft = true }) => {
  const navigate = useNavigate();
  return (
    <Flex w="100%" flexDir={"column"}>
      {[
        { label: "Productos", route: AppRoute.Products },
        {
          label: "Consultorías",
          route: AppRoute.Consulting,
        },
      ].map((button) => (
        <Button
          key={`${button.label}-button`}
          variant="link"
          px={"16px"}
          pl={showPaddingLeft ? "36px !important" : 0}
          py={"8px"}
          textAlign={"left"}
          display={"block"}
          color="secondary.300"
          borderRadius={"8px"}
          onClick={() => navigate(button.route)}
          {...(useIsSubPageActive([button.route]) &&
            activeStyles.subPageButton)}
          _hover={{ bg: "neutral.100" }}
        >
          <Text variant={"placehoder"} fontSize={"14px"}>
            {button.label}
          </Text>
        </Button>
      ))}
    </Flex>
  );
};

export default ManageTabs;
