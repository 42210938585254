/* eslint-disable @typescript-eslint/no-use-before-define */
import {
  Flex,
  Heading,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import useGA4Event, {
  GA4EventAction,
  GA4EventCategory,
  GA4EventLabel,
} from "@/hooks/useGA4Event";
import { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useQueryLiveSessions } from "./utils";
import { LiveSessionCard as LiveSessionCardType } from "./types";
import { BigSpinner } from "@/components/BigSpinner";
import { useUser } from "@/providers/useUser";
import { useLazyQuery, useMutation } from "@apollo/client";
import { IsSessionScheduledAtSameTimeDocument } from "@/components/LiveSessions/graphql/isSessionsScheduledAtSameTIme.generated";
import { LiveContentType } from "@/components/LiveSessions/types/LiveBar.type";
import { LiveSession, LiveSessionType, UserRole } from "@/schemaTypes";
import { CreateLiveSessionSchedulingDocument } from "@/components/LiveSessions/graphql/CreateSchullingSessions.generated";
import { AppRoute } from "@/AppRoute";
import { useNavigate } from "react-router-dom";
import LiveSessionCard from "./LiveSessionCard";
import ModalSchedulePosition from "@/components/LiveSessions/ModalSchedulePosition";
import ModalCancelSchedullingLiveSession from "@/components/LiveSessions/ModalCancelSchedullingLiveSession";
import moment from "moment";
import AcademyCarousel from "@/components/AcademyCarousel";
import PreviewCard from "./PreviewCard";

interface LiveSessionsProps {
  level?: string | null;
  teacher?: string | null;
  fullTeachers?: string[];
  matchLiveSessionsTeachers?: (matchTeachers: string[]) => void;
}

const LiveSessions = ({
  level,
  teacher,
  fullTeachers,
  matchLiveSessionsTeachers,
}: LiveSessionsProps) => {
  const { timezone, hasRoles } = useUser();
  const { sendEvent } = useGA4Event();
  const navigate = useNavigate();
  const isMobile = useBreakpointValue({ base: true, lg: false });

  const {
    liveSessions,
    schedulledLiveSessions,
    liveSessionsLoading,
    refetchLiveSessions,
  } = useQueryLiveSessions(false);

  const [sessions, setSessions] =
    useState<Array<LiveSessionCardType | undefined>>(liveSessions);
  const [fullSessions, setFullSessions] =
    useState<Array<LiveSessionCardType | undefined>>(liveSessions);

  const [schedulledSessions, setScheduledSessions] = useState<
    Array<LiveSessionCardType | undefined>
  >(schedulledLiveSessions);
  const [fullScheduledSessions, setFullScheduledSessions] = useState<
    Array<LiveSessionCardType | undefined>
  >(schedulledLiveSessions);
  const [matchingTeachers, setMatchingTeachers] = useState<string[]>([]);

  const [selecetedGoingOnSession, setSelectedGoingOnSession] = useState<
    LiveSessionCardType | undefined
  >();

  const [sessionToSchedule, setSessionToSchedule] = useState<
    LiveSessionCardType | undefined
  >();

  const [sessionToSchedulesSameTime, setSessionToScheduleSameTime] =
    useState<LiveContentType>();

  const [verifySessionScheduledAtSameTime] = useLazyQuery(
    IsSessionScheduledAtSameTimeDocument,
    { fetchPolicy: "no-cache" }
  );
  const handleVerifySchedulingSession = async (
    session: LiveSessionCardType
  ) => {
    const haSsessionScheduled = await verifySessionScheduledAtSameTime({
      variables: { liveSessionsId: session?.id },
    });

    if (haSsessionScheduled.data?.isSessionScheduledAtSameTime) {
      setCanSchedule(false);
      setSessionToSchedule(session);
      setSessionToScheduleSameTime(
        haSsessionScheduled?.data?.isSessionScheduledAtSameTime
      );
    } else {
      setCanSchedule(true);
      setSessionToSchedule(session);
    }
  };

  const [createSchedullingSession] = useMutation(
    CreateLiveSessionSchedulingDocument
  );
  const handleSchedullingSession = async (session?: LiveSessionCardType) => {
    if (session && timezone) {
      createSchedullingSession({
        variables: {
          createLiveSessionsSchedulingInput: {
            liveSessionsId: session?.id,
            timezone: timezone,
          },
        },
      }).then(() => {
        refetchLiveSessions();
        handleScheduleModalClose();
        sendEvent({
          action: `${GA4EventAction.RegisterLiveSessionAttendance}-${session.name}`,
          label: GA4EventLabel.RegisterLiveSessionAttendance,
          category: GA4EventCategory.LiveSessions,
        });
      });
    }
  };

  const [canSchedule, setCanSchedule] = useState(false);
  const [isScheduleModalOpen, setIsScheduleModalOpen] = useState(false);
  const [isCancelScheduleModalOpen, setIsCancelScheduleModalOpen] =
    useState(false);

  const handleScheduleClick = (session: LiveSessionCardType) => {
    handleVerifySchedulingSession(session).then(() =>
      setIsScheduleModalOpen(true)
    );
  };

  const handleScheduleModalClose = () => {
    setIsScheduleModalOpen(false);
    setSessionToSchedule(undefined);
  };

  const handleCancelScheduleClick = (session: LiveSessionCardType) => {
    setSessionToSchedule(session);
    setIsCancelScheduleModalOpen(true);
  };

  const handleCancelScheduleModalClose = () => {
    setIsCancelScheduleModalOpen(false);
    setSessionToSchedule(undefined);
  };

  const isMentor = hasRoles([UserRole.Mentor]);

  const allFilters = [
    { name: "Todos", value: "Todos" },
    { name: "Clases en vivo", value: LiveSessionType.Livesession },
    { name: "Masterclasses", value: LiveSessionType.Masterclass },
    { name: "Reunión de ventas", value: LiveSessionType.SalesSession },
    { name: "Talleres de inicio", value: LiveSessionType.Workshop },
    {
      name: "Preguntas y respuestas",
      value: LiveSessionType.QuestionsAndAnswers,
    },
  ];

  const filtersWithData = (filterScheduled = false) => {
    return allFilters.filter(
      (filter) =>
        filter.value === "Todos" ||
        (filterScheduled
          ? schedulledLiveSessions.some(
              (session) => session?.liveSessionType === filter.value
            )
          : liveSessions.some(
              (session) => session?.liveSessionType === filter.value
            ))
    );
  };

  const handleFilterClick = (newFilter: string, filterScheduled = false) => {
    const newFilterValue =
      filtersWithData(filterScheduled).find(
        (filter) => filter.name === newFilter
      )?.value || "Todos";

    const sessionsToFilter = filterScheduled
      ? schedulledLiveSessions
      : liveSessions;

    const filteredSessions = sessionsToFilter.filter(
      (session) =>
        newFilterValue === "Todos" ||
        session?.liveSessionType === newFilterValue
    );

    return filterScheduled
      ? setScheduledSessions(filteredSessions)
      : setSessions(filteredSessions);
  };

  useEffect(() => {
    const applyFilters = () => {
      let filteredSessions = [...fullSessions];
      let filteredScheduledSessions = [...fullScheduledSessions];

      if (level !== "Todos") {
        filteredSessions = filteredSessions.filter(
          (session) => session?.level?.name === level
        );
        filteredScheduledSessions = filteredScheduledSessions.filter(
          (session) => session?.level?.name === level
        );
      }

      if (teacher !== "Todos") {
        filteredSessions = filteredSessions.filter(
          (session) => session?.mentorName === teacher
        );
        filteredScheduledSessions = filteredScheduledSessions.filter(
          (session) => session?.mentorName === teacher
        );
      }

      if (level === "Todos" && teacher === "Todos") {
        setSessions(fullSessions);
        setScheduledSessions(fullScheduledSessions);
      } else {
        setSessions(filteredSessions);
        setScheduledSessions(filteredScheduledSessions);
      }
    };

    applyFilters();
  }, [level, teacher, fullSessions, fullScheduledSessions]);

  useEffect(() => {
    const findMatchingTeachers = () => {
      const allSessions = [...fullSessions, ...fullScheduledSessions];
      const uniqueTeachers = new Set<string>();

      allSessions.forEach((session) => {
        if (
          session &&
          fullTeachers &&
          fullTeachers.includes(session.mentorName)
        ) {
          uniqueTeachers.add(session.mentorName);
        }
      });

      setMatchingTeachers(Array.from(uniqueTeachers));
    };

    findMatchingTeachers();
  }, [fullSessions, fullScheduledSessions, fullTeachers]);

  const playRandomSession = async () => {
    const goingOnSessions = schedulledSessions.filter(
      (session) => session?.isLive
    );

    if (!goingOnSessions.length) return setSelectedGoingOnSession(undefined);

    const randomSession =
      goingOnSessions[Math.floor(Math.random() * goingOnSessions.length)];

    if (randomSession) {
      setSelectedGoingOnSession(randomSession);
    } else {
      setSelectedGoingOnSession(undefined);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const isEnterAllowed = (startDate: Date, isMentor: boolean) => {
    const now = moment();
    const momentStartDate = isMentor
      ? moment(startDate).clone().subtract(5, "minutes")
      : moment(startDate);
    return now.isSameOrAfter(momentStartDate);
  };

  const enterToSession = (session: LiveSessionCardType) => {
    if (!isEnterAllowed(session.startDate, isMentor)) return;

    const liveSessionUrl = isMentor
      ? `${AppRoute.SettingDevices}/${session?.id}?redirect=${window.location.href}`
      : `${AppRoute.LiveSessionStreaming}/${session?.id}`;

    navigate(liveSessionUrl);
  };

  useEffect(() => {
    setSessions(liveSessions);
    setFullSessions(liveSessions);
    setScheduledSessions(schedulledLiveSessions);
    setFullScheduledSessions(schedulledLiveSessions);
  }, [liveSessionsLoading]);

  useEffect(() => {
    playRandomSession();
  }, [schedulledLiveSessions, liveSessions]);

  useEffect(() => {
    matchLiveSessionsTeachers?.(matchingTeachers);
  }, [matchingTeachers]);

  return (
    <Flex w={"100%"} pt={"32px"} px={0} flexDir={"column"}>
      <Heading as={"h5"} mb={"16px"}>
        <Trans>Live Sessions</Trans>
      </Heading>

      <Tabs isFitted>
        <TabList color={"neutral.300"} maxW={"max-content"}>
          <Tab
            _selected={{ color: "secondary.300" }}
            _active={{ color: "secondary.300" }}
          >
            <Text variant={"caption"}>Próximas</Text>
          </Tab>
          <Tab
            _selected={{ color: "secondary.300" }}
            _active={{ color: "secondary.300" }}
          >
            <Text variant={"caption"}>Agendadas</Text>
          </Tab>
        </TabList>

        <TabIndicator
          mt="-2px"
          height="2px"
          bg="secondary.300"
          borderRadius="8px"
        />

        {liveSessionsLoading && <BigSpinner />}

        <TabPanels>
          <TabPanel p={0} mt={"16px"}>
            {!sessions.length && !liveSessionsLoading && (
              <Text mt={"32px"} mb={"24px"} variant={"placeholder"}>
                En este momento no tenemos sesiones disponibles.
              </Text>
            )}
            {!!sessions.length && !liveSessionsLoading && (
              <AcademyCarousel
                filterOptions={filtersWithData().map((filter) => filter.name)}
                handleFilterChange={handleFilterClick}
              >
                {selecetedGoingOnSession && (
                  <Flex minW={"300px"} maxW={isMobile ? "100%" : "300px"}>
                    <PreviewCard
                      sessionSelectedStarted={
                        selecetedGoingOnSession as unknown as LiveSession
                      }
                      enterToSession={() =>
                        enterToSession(selecetedGoingOnSession)
                      }
                    />
                  </Flex>
                )}
                {sessions?.map((session) => (
                  <LiveSessionCard
                    key={session?.name}
                    session={session as LiveSessionCardType}
                    handleScheduleClick={handleScheduleClick}
                    handleCancelScheduleClick={handleCancelScheduleClick}
                    enterToSession={enterToSession}
                  />
                ))}
              </AcademyCarousel>
            )}
          </TabPanel>

          <TabPanel p={0} mt={"16px"}>
            {!sessions.length && !liveSessionsLoading && (
              <Text mt={"32px"} mb={"24px"} variant={"placeholder"}>
                En este momento no tienes sesiones agendadas.
              </Text>
            )}
            {!!sessions.length && !liveSessionsLoading && (
              <AcademyCarousel
                filterOptions={filtersWithData(true).map(
                  (filter) => filter.name
                )}
                handleFilterChange={(filter) => handleFilterClick(filter, true)}
              >
                {selecetedGoingOnSession && (
                  <Flex minW={"300px"} maxW={isMobile ? "100%" : "300px"}>
                    <PreviewCard
                      sessionSelectedStarted={
                        selecetedGoingOnSession as unknown as LiveSession
                      }
                      enterToSession={() =>
                        enterToSession(selecetedGoingOnSession)
                      }
                    />
                  </Flex>
                )}
                {schedulledSessions?.map((session) => (
                  <LiveSessionCard
                    key={session?.name}
                    session={session as LiveSessionCardType}
                    handleScheduleClick={handleScheduleClick}
                    handleCancelScheduleClick={handleCancelScheduleClick}
                    enterToSession={enterToSession}
                  />
                ))}
              </AcademyCarousel>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>

      <ModalSchedulePosition
        isOpen={isScheduleModalOpen}
        onClose={handleScheduleModalClose}
        timezone={timezone}
        sessionToSchedule={sessionToSchedule}
        sessionToSchedulesSameTime={sessionToSchedulesSameTime}
        handleSchedullingSession={handleSchedullingSession}
        isSuccessSchedulling={canSchedule}
      />

      <ModalCancelSchedullingLiveSession
        isOpen={isCancelScheduleModalOpen}
        onClose={handleCancelScheduleModalClose}
        sessionToSchedule={sessionToSchedule}
        setupdateRequests={setCanSchedule}
        sessionByWeek={sessionToSchedule}
        refetchLiveSessions={refetchLiveSessions}
      />
    </Flex>
  );
};

export default LiveSessions;
