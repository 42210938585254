import { Box, Flex, Text } from "@chakra-ui/react";
import { useCallback } from "react";
import { IconControlsCamera, TypeIcon } from "../IconsControls";
import useCamera from "@/components/LiveSessionsZoom/Hooks/useCamera";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import { t } from "i18next";
import { useUser } from "@/providers/useUser";
import { UserRole } from "@/schemaTypes";

export const Camera = () => {
  const { hasRoles } = useUser();
  const isMentor = hasRoles([UserRole.Mentor]);
  const { isVideoActive, toggleVideo } = useCamera();

  const onCameraHandle = useCallback(async () => {
    try {
      if (isMentor) {
        await toggleVideo();
      }
    } catch (error) {
      SBErrorPubSub.publish({
        component: "Camera.tsx line 16",
        message: t("Could not toggle camera"),
        showInProd: true,
      });
    }
  }, [toggleVideo, isMentor]);

  return (
    <Box>
      <Flex
        onClick={onCameraHandle}
        cursor={"pointer"}
        w={"115px"}
        maxW={"115px"}
        userSelect={"none"}
        justify={"center"}
        align={"center"}
        flexDir={"column"}
      >
        <IconControlsCamera
          type={isVideoActive ? TypeIcon.CAMERA_ON : TypeIcon.CAMERA_OFF}
        />
        <Text
          color={isVideoActive ? "#00E324" : "#FF4848"}
          fontSize={"10px"}
          lineHeight={"19.2px"}
        >
          Video {isVideoActive ? "activo" : "inactivo"}
        </Text>
      </Flex>
    </Box>
  );
};
