import React, { useContext, useEffect, useRef, useState } from "react";
import ZoomContext from "../context/zoom-context";
import { useLazyQuery } from "@apollo/client";
import { GetJwtAuthLiveSessionDocument } from "../graphql/getJwtAuthLiveSession.generated";
import { useUser } from "@/providers/useUser";
import { Box } from "@chakra-ui/react";
import { VideoQuality } from "@zoom/videosdk";
import "./index.css";
import { useParams } from "react-router-dom";
import { EVENTS } from "../types/LiveBar.type";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import { t } from "i18next";
interface LivePreviewProps {
  liveSessionId: string;
}

const STATUS_IMAGES = {
  CAMERA_OFF: "/img/camara-off.webp",
  CLASS_STARTED: "/img/cv-started.webp",
  CLASS_FINISHED: "/img/cv-finished.webp",
};

const LivePreview: React.FC<LivePreviewProps> = (props) => {
  const { liveSessionId } = props;
  const { name: userName, userId: userIdURL } = useParams<{
    liveSessionId: string;
    name: string;
    userId: string;
  }>();
  const { user } = useUser();
  const [isSharedScreen, setisSharedScreen] = useState(false);
  const [isSharedCamera, setisSharedCamera] = useState(false);
  const { zmClient, clientState, initZoomClient, dispatch } =
    useContext(ZoomContext);
  const [isConnected, setIsConnected] = useState(false);
  const shareRef = useRef<HTMLCanvasElement | null>(null);
  const [getJwtAuthLiveSession] = useLazyQuery(GetJwtAuthLiveSessionDocument);
  const [canvasSize, setcanvasSize] = useState({
    width: 1280,
    height: 720,
  });

  const disconnectedUser = async () => {
    if (zmClient) {
      await zmClient.leave();
      setIsConnected(false);
      dispatch({
        type: "DISCONNECT_CLIENT",
        payload: {
          ...clientState,
          isAuth: false,
          token: undefined,
          topic: undefined,
          userName: undefined,
          zmClient: undefined,
          error: undefined,
        },
      });
    }
  };

  useEffect(() => {
    const connectToLiveSession = async () => {
      if (clientState && clientState.isAuth) {
        return;
      }

      try {
        const variablesGraphql = {
          liveSessionsId: liveSessionId,
          liveStream: false,
          isPortal1: !!userName,
          dataPortal1Input: {
            userId: userIdURL || "",
          },
        };

        const { data } = await getJwtAuthLiveSession({
          variables: variablesGraphql,
        });

        if (data && initZoomClient) {
          const {
            jwt,
            session: { channelName },
          } = data.getJwtAuthLiveSession;

          await initZoomClient({
            topic: channelName,
            token: jwt,
            username:
              (user?.name && `${user?.name} ${user?.lastName}`) ??
              "UserPreview",
          });

          setIsConnected(true);
        }
      } catch (error) {
        SBErrorPubSub.publish({
          component: "Live Preview line 79",
          message: t(
            "An error occurred while trying to connect to the live class 2"
          ),
          showInProd: true,
        });
      }
    };

    connectToLiveSession();

    return () => {
      disconnectedUser();
    };
  }, [zmClient]);

  const startRenderSharedContent = async (userId: number) => {
    if (shareRef.current) {
      await zmClient?.getMediaStream().startShareView(shareRef.current, userId);
      setisSharedScreen(true);
    }
  };

  const stopRenderCamera = async (userId: number) => {
    if (shareRef.current) {
      const elements = await zmClient?.getMediaStream().detachVideo(userId);

      if (Array.isArray(elements)) {
        elements.forEach((e) => e.remove());
      } else if (elements) {
        elements.remove();
      }
    }
  };

  const startRenderCamera = async (userId: number) => {
    setisSharedCamera(true);

    if (shareRef.current) {
      zmClient
        ?.getMediaStream()
        .attachVideo(userId, VideoQuality.Video_360P)
        .then((videoElement) => {
          const videoContainer = document.querySelector(
            `video-player-container`
          );

          const elementToDelete = document.querySelector(
            `[node-id="${userId}"]`
          );

          if (videoContainer && !elementToDelete) {
            videoContainer.appendChild(videoElement as Node);
          }
        });
    }
  };

  const stopRenderSharedContent = async () => {
    await zmClient?.getMediaStream().stopShareView();
    setisSharedScreen(false);

    if (shareRef.current) {
      setcanvasSize({
        width: 1280,
        height: 720,
      });
    }
  };

  useEffect(() => {
    if (zmClient) {
      zmClient.on(EVENTS.ACTIVE_SHARE_CHANGE, (payload) => {
        if (payload.state === "Active") {
          zmClient.getAllUser().forEach((userZm) => {
            if (userZm.sharerOn) {
              startRenderSharedContent(payload.userId);
            }
          });
        } else {
          stopRenderSharedContent();
        }
      });

      zmClient.on(EVENTS.PEER_VIDEO_STATE_CHANGE, (payload) => {
        if (payload.action === "Start") {
          zmClient.getAllUser().forEach((userZm) => {
            if (userZm.bVideoOn) {
              startRenderCamera(payload.userId);
            }
          });
        } else {
          const numberVideoOn = zmClient
            .getAllUser()
            .filter((userZm) => userZm.bVideoOn);

          if (numberVideoOn.length === 0) {
            setisSharedCamera(false);
          }

          stopRenderCamera(Number(payload?.userId));
        }
      });
    }
  }, [zmClient]);

  useEffect(() => {
    if (zmClient && isConnected) {
      zmClient?.getAllUser().forEach((payload) => {
        if (payload.sharerOn) {
          startRenderSharedContent(payload.userId);
        }

        if (payload.bVideoOn) {
          startRenderCamera(payload.userId);
        }
      });
    }
  }, [isConnected, zmClient]);

  return (
    isConnected && (
      <div
        style={{
          height: "100%",
          backgroundColor: "black",
          backgroundImage: `url(${STATUS_IMAGES.CAMERA_OFF})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          borderRadius: "8px",
          overflow: "hidden",
        }}
      >
        <Box w={"100%"} height={"100%"} overflow={"hidden"} display={"flex"}>
          <Box hidden={!isSharedScreen} width={isSharedCamera ? "80%" : "100%"}>
            <canvas
              ref={shareRef}
              width={canvasSize.width}
              height={canvasSize.height}
              style={{ width: "100%", height: "100%", overflow: "hidden" }}
            />
          </Box>

          <Box
            hidden={!isSharedCamera}
            width={
              !isSharedScreen && isSharedCamera
                ? "100%"
                : isSharedScreen && !isSharedCamera
                ? "0"
                : "30%"
            }
            height={"100%"}
            display={"flex"}
          >
            <video-player-container></video-player-container>
          </Box>
        </Box>
      </div>
    )
  );
};

export default LivePreview;
