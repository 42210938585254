import {
  Box,
  Grid,
  Heading,
  Textarea,
  Text,
  useToast,
  FormLabel,
  FormControl,
  Select,
  Flex,
  Checkbox,
} from "@chakra-ui/react";
import { InputField } from "../Forms/InputField";
import { DatePickerField } from "../Forms/DatePickerField";
import { useEffect, useState } from "react";
import {
  CreateMasterClassInput,
  LiveSession,
  LiveSessionType,
  UserRole,
} from "@/schemaTypes";
import moment from "moment";
import { useUser } from "@/providers/useUser";
import { Trans, useTranslation } from "react-i18next";
import { InputStyled } from "../Forms/InputStyled";
import {
  SearchUserDocument,
  SearchUserQuery,
} from "../Users/graphql/searchUser.generated";
import { useLazyQuery, useMutation } from "@apollo/client";
import { UserCardProps } from "../Users/UserCard";
import ButtonCustom from "../../components/Button/Button";
import { CreateMasterClassDocument } from "./graphql/createMasterClass.generated";
import { SBErrorPubSub } from "@/utils/errors/SBError";
import { UpdateMasterClassDocument } from "./graphql/updateMasterClass.generated";
import { SearchByIdDocument } from "../Users/graphql/searchUserById.generated";
import { useCategoriesOptions } from "@/pages/academy/LiveClasses/utils";

interface CreateMasterClassProps {
  fetchMasterClass: () => void;
  editMode?: boolean;
  masterClass?: LiveSession;
  closeModal: () => void;
}

const CreateMasterClass = ({
  fetchMasterClass,
  editMode,
  masterClass,
  closeModal,
}: CreateMasterClassProps) => {
  const { user } = useUser();
  const { t } = useTranslation();
  const toast = useToast();
  const [searchTerm, setSearchTerm] = useState<string>(
    editMode && masterClass?.mentorName ? masterClass?.mentorName : ""
  );
  const [createMasterClass, { loading: createMasterClassLoading }] =
    useMutation(CreateMasterClassDocument);
  const [updateMasterClass, { loading: updateMasterClassLoading }] =
    useMutation(UpdateMasterClassDocument);
  const [userResults, setUserResults] = useState<
    SearchUserQuery["searchUsers"] | undefined
  >();
  const { brandCategories, levelCategories, subCategories } =
    useCategoriesOptions();

  const [selectedMentor, setselectedMentor] = useState(
    editMode
      ? {
          id: masterClass?.mentorId || "",
          name: masterClass?.mentorName || "",
        }
      : {
          id: "",
          name: "",
        }
  );
  const [searchUsers, { data }] = useLazyQuery(SearchUserDocument);
  const [searchUserById, { data: dataUserById }] =
    useLazyQuery(SearchByIdDocument);
  const [dataMasterClass, setdataMasterClass] =
    useState<CreateMasterClassInput>(
      editMode && masterClass
        ? {
            endDate: masterClass.endDate,
            name: masterClass.name,
            startDate: masterClass.startDate,
            userId: String(user?.id),
            liveSessionType: LiveSessionType.Masterclass,
            description: masterClass.description || "",
            imageUrlMentor: masterClass.imageUrlMentor || "",
            mentorId: masterClass.mentorId || "",
            brandId: masterClass.brand?.id || "",
            subCategoryId: masterClass.subCategory?.id || "",
            levelId: masterClass.level?.id || "",
          }
        : {
            endDate: moment().add(1, "hour").toDate(),
            name: "",
            startDate: moment().toDate(),
            userId: String(user?.id),
            liveSessionType: LiveSessionType.Masterclass,
            description: "",
            imageUrlMentor: "",
            mentorId: "",
            brandId: "",
            subCategoryId: "",
            levelId: "",
          }
    );

  const handleStartDateChange = (start: Date | undefined | null) => {
    if (start !== null && start !== undefined) {
      const dateStart = moment(start);
      const formattedStartDate = dateStart.format("YYYY-MM-DDTHH:mm:ssZ");

      setdataMasterClass({
        ...dataMasterClass,
        startDate: new Date(formattedStartDate),
      });
    }
  };

  const handleEndDateChange = (end: Date | undefined | null) => {
    if (end !== null && end !== undefined) {
      const dateStart = moment(end);
      const formattedEndDate = dateStart.format("YYYY-MM-DDTHH:mm:ssZ");

      setdataMasterClass({
        ...dataMasterClass,
        endDate: new Date(formattedEndDate),
      });
    }
  };

  const handleUserSelect = (userMentor: UserCardProps["user"]) => {
    setdataMasterClass({
      ...dataMasterClass,
      mentorId: userMentor.id,
    });

    setselectedMentor({
      id: userMentor.id,
      name: userMentor.name,
    });

    setSearchTerm("");
  };

  const handleChangeIsWorkshop = () => {
    setdataMasterClass({
      ...dataMasterClass,
      isWorkshop: !dataMasterClass.isWorkshop,
    });
  };

  const handleSearchTermChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedSearchTerm = e.target.value;
    setSearchTerm(selectedSearchTerm);
    setselectedMentor({
      id: "",
      name: "",
    });
  };

  const handleChangeField = (value: string) => {
    setdataMasterClass({
      ...dataMasterClass,
      name: value,
    });
  };

  const isValidForm = () => {
    const { name, startDate, endDate, mentorId, imageUrlMentor, description } =
      dataMasterClass;
    return (
      name && startDate && endDate && mentorId && imageUrlMentor && description
    );
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    e.preventDefault();
    setdataMasterClass({
      ...dataMasterClass,
      [e.target.name]: e.target.value,
    });
  };

  const handleCreateMasterClass = async () => {
    if (isValidForm()) {
      if (!editMode) {
        const dataCreate = await createMasterClass({
          variables: {
            createMasterClassInput: dataMasterClass,
          },
        });
        if (dataCreate.data?.createMasterClass) {
          toast({
            title: t(`Live Session created`),
            status: "success",
            duration: 2000,
            isClosable: true,
          });

          fetchMasterClass();

          setdataMasterClass({
            endDate: moment().toDate(),
            name: "",
            startDate: moment().toDate(),
            userId: String(user?.id),
            liveSessionType: LiveSessionType.Masterclass,
            description: "",
            imageUrlMentor: "",
            mentorId: "",
            brandId: "",
            subCategoryId: "",
            levelId: "",
          });

          setselectedMentor({
            id: "",
            name: "",
          });
          closeModal();
        } else {
          SBErrorPubSub.publish({
            component: "CreateMasterClass.tsx line 177",
            message: t(`Error creating live session, try again late`),
            showInProd: true,
          });
        }
      } else {
        const dataUpdate = await updateMasterClass({
          variables: {
            updateMasterClassInput: {
              id: masterClass?.id || "",
              ...dataMasterClass,
            },
          },
        });
        if (dataUpdate.data?.updateMasterClass) {
          toast({
            title: t(`MasterClass Updated`),
            status: "success",
            duration: 2000,
            isClosable: true,
          });

          fetchMasterClass();

          setdataMasterClass({
            endDate: moment().toDate(),
            name: "",
            startDate: moment().toDate(),
            userId: String(user?.id),
            liveSessionType: LiveSessionType.Masterclass,
            description: "",
            imageUrlMentor: "",
            mentorId: "",
            brandId: "",
            subCategoryId: "",
            levelId: "",
          });

          setselectedMentor({
            id: "",
            name: "",
          });
          closeModal();
        } else {
          SBErrorPubSub.publish({
            component: "CreateMasterClass.tsx line 177",
            message: t(`Error creating live session, try again late`),
            showInProd: true,
          });
        }
      }
    }
  };

  useEffect(() => {
    if (data) {
      setUserResults(data.searchUsers);
    }
  }, [data]);

  useEffect(() => {
    if (dataUserById) {
      setselectedMentor({
        id: dataUserById.searchById[0].id,
        name: dataUserById.searchById[0].name,
      });
    }
  }, [dataUserById]);

  useEffect(() => {
    if (masterClass?.mentorId) {
      searchUserById({
        variables: {
          usersIds: [masterClass?.mentorId],
        },
      });
    }
    if (searchTerm && searchTerm.length > 0) {
      searchUsers({
        variables: {
          userSearchInput: {
            query: searchTerm,
            roles: [UserRole.Mentor],
            entityName: "user",
          },
        },
      });
    } else {
      setUserResults(undefined);
    }
  }, [searchTerm, masterClass]);

  return (
    <>
      <Box>
        <Grid mb="4" justifyContent="stretch" autoFlow="column" gap="4">
          <Box display={"flex"} flexDirection={"column"} gap={2}>
            <Box display={"flex"} flexDirection={"column"} gap={2}>
              <Heading as={"h5"} size="md" mb="2" mt="4">
                <Trans>Masterclass information</Trans>
              </Heading>

              <InputField
                value={dataMasterClass.name}
                name="name"
                isRequired
                label={t("MasterClass name")}
                validator={(value: string) =>
                  !value || value.length > 2
                    ? []
                    : [t("Invalid live class name")]
                }
                onChange={handleChangeField}
                mb="3"
              />
              <Flex>
                <Text
                  fontWeight={"semibold"}
                  fontSize={"14px"}
                  mb="4px"
                  w={"200px"}
                >
                  <Trans>Es taller de inicio?</Trans>
                </Text>
                <Checkbox
                  isChecked={dataMasterClass.isWorkshop ?? false}
                  onChange={handleChangeIsWorkshop}
                />
              </Flex>

              <Text fontWeight={"semibold"} fontSize={"14px"} mb="4px">
                <Trans>Description</Trans>
              </Text>

              <Textarea
                value={dataMasterClass.description}
                name="description"
                onChange={handleInputChange}
                placeholder={t("Description of the event")}
                size="sm"
              />

              <Box mt={5}>
                <DatePickerField
                  value={dataMasterClass.startDate}
                  isRequired
                  label={t("Start Date & Time")}
                  onChange={handleStartDateChange}
                  showTimeSelect
                />
                <DatePickerField
                  value={dataMasterClass.endDate}
                  isRequired
                  label={t("End Date & Time")}
                  onChange={handleEndDateChange}
                  showTimeSelect
                />
              </Box>

              <Flex mb={3}>
                <InputField
                  name="imageUrlMentor"
                  label="Mentor avatar"
                  value={dataMasterClass.imageUrlMentor}
                  onChange={(value) =>
                    setdataMasterClass({
                      ...dataMasterClass,
                      imageUrlMentor: value,
                    })
                  }
                />
              </Flex>

              <FormControl isRequired>
                <FormLabel>{t("Session brand")}</FormLabel>
                <Select
                  onChange={(value) =>
                    setdataMasterClass({
                      ...dataMasterClass,
                      brandId: value.target.value,
                    })
                  }
                  value={dataMasterClass.brandId}
                >
                  <option value="" disabled>
                    {t("Select a brand")}
                  </option>
                  {brandCategories?.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>{t("Session subCategory")}</FormLabel>
                <Select
                  onChange={(value) =>
                    setdataMasterClass({
                      ...dataMasterClass,
                      subCategoryId: value.target.value,
                    })
                  }
                  value={dataMasterClass.subCategoryId}
                >
                  <option value="" disabled>
                    {t("Select a subCategory")}
                  </option>
                  {subCategories?.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <FormControl isRequired>
                <FormLabel>{t("Session level")}</FormLabel>
                <Select
                  onChange={(value) =>
                    setdataMasterClass({
                      ...dataMasterClass,
                      levelId: value.target.value,
                    })
                  }
                  value={dataMasterClass.levelId}
                >
                  <option value="" disabled>
                    {t("Select a level")}
                  </option>
                  {levelCategories?.map((category) => (
                    <option key={category.id} value={category.id}>
                      {category.name}
                    </option>
                  ))}
                </Select>
              </FormControl>

              <Box>
                <Box>
                  <Text
                    fontWeight={"semibold"}
                    fontSize={"14px"}
                    mb="4px"
                    mt={"4px"}
                  >
                    <Trans>Mentor</Trans>
                  </Text>
                  <InputStyled
                    pl="8"
                    value={selectedMentor.name || searchTerm}
                    onChange={handleSearchTermChange}
                    placeholder={t("User Search")}
                  />
                </Box>
                <Box>
                  {searchTerm && searchTerm.length > 0 && (
                    <Box
                      backgroundColor={"shades.white"}
                      overflow={"auto"}
                      height={"300px"}
                    >
                      {userResults?.map((userMentor) => (
                        <Box
                          key={userMentor.id}
                          onClick={() => handleUserSelect(userMentor)}
                        >
                          {userMentor.name}
                        </Box>
                      ))}
                    </Box>
                  )}
                </Box>
                <Box mt={"5"}>
                  <ButtonCustom
                    isLoading={
                      createMasterClassLoading || updateMasterClassLoading
                    }
                    isDisabled={isValidForm() ? false : true}
                    title={
                      editMode
                        ? t("Update Masterclass")
                        : t("Generate Masterclass")
                    }
                    variant="primary"
                    onClick={handleCreateMasterClass}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Box>
    </>
  );
};

export default CreateMasterClass;
